import React from "react";
import { Box, Button, Grid, Icon, ListItem, Typography } from "@mui/material";

const ReturnCards = () => {
  return (
    <Grid container spacing={3} justifyContent={"center"}>
      <Grid item xs={12} md={6} xl={4}>
        <Box bgcolor={"#F3F3F3"} height={"100%"} position={"relative"} pb={10}>
          <Box
            bgcolor={"#B98E1D"}
            px={2}
            height={{ xs: 60, md: 165 }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Typography
              fontSize={{ xs: 20, md: 25 }}
              fontWeight={400}
              color={"White"}>
              Product Returns
            </Typography>
          </Box>
          <Box py={5} px={3}>
            <Box
              component="ul"
              sx={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}>
              <ListItem style={{ marginBottom: 10 }}>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Choose products online from order history.
                </Typography>
              </ListItem>
              <ListItem style={{ marginBottom: 10 }}>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Automated forms ready to print
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Use your own shipping and insurance to send to the return
                  address below.
                </Typography>
              </ListItem>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "9%",
                left: 0,
                right: 0,
                margin: "0 auto",
                textAlign: "center",
              }}>
              <Button
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#86715E",
                  textTransform: "capitalize",
                  color: "white",
                  px: 5,
                  fontSize: 16,
                  fontWeight: 700,
                }}>
                Start Return
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Box bgcolor={"#F3F3F3"} height={"100%"} position={"relative"} pb={10}>
          <Box
            bgcolor={"#B98E1D"}
            px={2}
            height={165}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Box textAlign={"center"}>
              <Typography fontSize={16} fontWeight={400} color={"White"}>
                Easiest + Fastest
              </Typography>
              <Typography fontSize={25} fontWeight={400} color={"White"}>
                Product Returns
              </Typography>
              <Typography fontSize={16} fontWeight={400} color={"White"}>
                <b>24.99</b> using credit card
              </Typography>
            </Box>
          </Box>
          <Box py={5} px={3}>
            <Box
              component="ul"
              sx={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}>
              <ListItem style={{ marginBottom: 10 }}>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Includes next-day shipping and insurance up to $2,500 for the
                  fastest refund
                </Typography>
              </ListItem>
              <ListItem style={{ marginBottom: 10 }}>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Convenient shipping label ready to print
                </Typography>
              </ListItem>
              <ListItem style={{ marginBottom: 10 }}>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Choose products online from order history
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Automated forms ready to print
                </Typography>
              </ListItem>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "9%",
                left: 0,
                right: 0,
                margin: "0 auto",
                textAlign: "center",
              }}>
              <Button
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#86715E",
                  textTransform: "capitalize",
                  color: "white",
                  px: 5,
                  fontSize: 16,
                  fontWeight: 700,
                }}>
                Start Return
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Box bgcolor={"#F3F3F3"} height={"100%"} position={"relative"}>
          <Box
            bgcolor={"#B98E1D"}
            px={2}
            height={{ xs: 60, md: 165 }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Typography
              fontSize={{ xs: 20, md: 25 }}
              fontWeight={400}
              color={"White"}>
              Manual Return
            </Typography>
          </Box>
          <Box py={5} px={3}>
            <Box
              component="ul"
              sx={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}>
              <ListItem style={{ marginBottom: 10, display: "block" }}>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />
                  Fill out the following Return Form manually and place inside
                  return shipment
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyle: "none",
                    padding: "0 0 0 30px",
                    marginTop: 1,
                  }}>
                  <ListItem style={{ marginBottom: 10 }}>
                    <Typography fontSize={16} fontWeight={400} display={"flex"}>
                      <Icon
                        style={{
                          lineHeight: "normal",
                          color: "#B98E1D",
                          height: "initial",
                          marginTop: 5,
                          marginRight: 15,
                          fontSize: 10,
                        }}
                        className={"ri-circle-fill"}
                      />
                      USA and International Merchandise Return Form
                    </Typography>
                  </ListItem>
                  <ListItem style={{ marginBottom: 10 }}>
                    <Typography fontSize={16} fontWeight={400} display={"flex"}>
                      <Icon
                        style={{
                          lineHeight: "normal",
                          color: "#B98E1D",
                          height: "initial",
                          marginTop: 5,
                          marginRight: 15,
                          fontSize: 10,
                        }}
                        className={"ri-circle-fill"}
                      />
                      Canada Merchandise Return Form
                    </Typography>
                  </ListItem>
                </Box>
              </ListItem>
              <ListItem>
                <Typography fontSize={16} fontWeight={400} display={"flex"}>
                  <Icon
                    style={{
                      lineHeight: "normal",
                      color: "#D9D9D9",
                      height: "initial",
                      marginRight: 20,
                    }}
                    className={"ri-circle-fill"}
                  />{" "}
                  Use your own shipping and insurance to send to the return
                  address below
                </Typography>
              </ListItem>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReturnCards;
