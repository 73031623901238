import {
  Box,
  Button,
  Grid,
  Icon,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import BreadcrumbsPage from "../../components/common/breadcrumbs";

import ReturnCards from "./ReturnCards";
import ReturnBanner from "./ReturnBanner";

const Return = () => {
  const data = [
    {
      title: "Product",
      value: "No Fee Return Period Calendar Days from Invoice Date",
    },
    {
      title: "Stuller Diamonds® with Reports Notable Gems®",
      value: "10 Days",
    },
    {
      title:
        "Jewelry Tools and Supplies Packaging and Displays Fabricated Metals and Stampings Watch Bands",
      value: "15 Days",
    },
    {
      title: "All Other Products",
      value: "30 Days",
    },
    {
      title: `Special Ordered Fabricated Metals Books, CDs, DVDs Imprinted Packaging/Displays Special Orders Selling Systems Deals Used Tools`,
      value: "Nonrefundable",
    },
  ];
  return (
    <>
      <Grid container gap={4}>
        <Grid container px={2} maxWidth="1580px" mx="auto">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/trade" },
            ]}
          />
        </Grid>
      </Grid>
      <Typography
        mt={3}
        mb={8}
        fontSize={40}
        fontWeight={300}
        textAlign={"center"}
        width={"100%"}>
        Returns
      </Typography>
      <ReturnBanner />
      <ReturnCards />
      <Typography mt={5} mb={8} fontSize={16} fontWeight={300}>
        *UPS Next Day Saver Shipping Method. Available only in the contiguous 48
        states. Excludes packages five pounds or greater, hazardous product, and
        oversize packages (irregular dimensions).
      </Typography>
      <Grid container spacing={4} mb={15}>
        <Grid item xs={12} lg={4}>
          <Typography
            fontSize={25}
            fontWeight={400}
            mb={2}
            textAlign={"center"}>
            Return address
          </Typography>
          <Box bgcolor={"#F3F3F3"} p={3}>
            <Typography fontSize={16} fontWeight={400}>
              SI
              <br />
              Dept 70
              <br />
              302 Rue Louis XIV
              <br />
              Lafayette, LA 70508
            </Typography>
            <Button
              sx={{
                backgroundColor: "transparent",
                px: 0,
                color: "#86715E",
                borderBottom: "2px solid #86715E",
                textTransform: "capitalize",
                borderRadius: 0,
                fontSize: 16,
                fontWeight: 600,
                paddingBottom: "1px",
                paddingTop: 0,
              }}>
              Show Canadian Return Address
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Typography fontSize={25} fontWeight={400} mb={2}>
            What you need to know
          </Typography>
          <Box
            component="ul"
            sx={{
              listStyle: "none",
              padding: 0,
              margin: "0 0 50px 0",
            }}>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Upon receiving and processing your return, we'll exchange,
                replace, or credit via original payment method within 3 business
                days
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Return products must be in original condition and please include
                all packaging, envelopes, bags, boxes, etc.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Insurance coverage must be for full value of return items as
                Stuller is not responsible for lost or damaged shipments
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Stuller is unable to accept hazardous materials or cash on
                delivery (COD) return shipments
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Additional fees may apply for abuse of return privileges, not
                following guidelines, or shipping back of unaccepted items
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Items returned because of manufacturing defects will be
                exchanged
              </Typography>
            </ListItem>
          </Box>
          <Typography fontSize={25} fontWeight={400} mb={2}>
            Tips for securely packaging your return
          </Typography>
          <Box
            component="ul"
            sx={{
              listStyle: "none",
              padding: 0,
              margin: 0,
            }}>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Use plain or nondescript packaging.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                When possible, do not display your company name or logo on the
                outside of packages being shipped to Stuller. Use secure/alias
                names to address a package.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                By limiting the amount of information you share about who you
                are, what you are shipping, and to whom the shipment is
                intended, you minimize the risk of fraudulent activity.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                If shipping multiple packages, avoid taping the boxes together.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Prepare shipments under a camera and capture the tracking number
                to have visual evidence of what was packed and shipped.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                For higher value items, ALWAYS double box. Sealing the ends of
                both boxes with clear tape, or tape with fibers, is always the
                best way to protect the contents from theft.
              </Typography>
            </ListItem>
            <ListItem
              style={{ marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}>
              <Typography fontSize={16} fontWeight={400} display={"flex"}>
                <Icon
                  style={{
                    lineHeight: "normal",
                    color: "black",
                    height: "initial",
                    marginTop: 7,
                    marginRight: 10,
                    fontSize: 6,
                  }}
                  className={"ri-circle-fill"}
                />
                Do not place your return in a drop box or leave it unattended.
                Schedule a pickup with the carrier or drop off inside the
                carrier’s facility and get a receipt with the tracking number.
              </Typography>
            </ListItem>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4} bgcolor={"#F3F3F3"} px={4} pt={2}>
        <Grid item xs={12} lg={6}>
          <Typography fontSize={25} fontWeight={400} mb={2}>
            Product Return Timelines
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            Returns of Stuller Diamonds® with Reports and Notable Gems® must be
            received by Stuller within 10 days of the invoice date. Finished
            Jewelry, Tools, Supplies, Packaging, and Fabricated Metals must be
            received by Stuller within 15 days of the invoice date and must be
            in original packaging. All other returns must be received within 30
            days of the invoice date. This includes Made to Order items, which
            are still considered returnable without additional customization
            services.
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            Products returned outside the No Fee Return Period are charged a 15%
            restocking fee.
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "0", overflow: "hidden", boxShadow: "none" }}>
            <Table>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? "white" : "#F3F3F3",
                      borderColor: "transparent",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,

                        border: "none",
                      }}>
                      <Typography fontSize={16} fontWeight={300} width={"45%"}>
                        {row.title}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        fontSize: 16,
                        border: "none",
                      }}>
                      <Typography fontSize={16} fontWeight={300}>
                        {row.value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography fontSize={25} fontWeight={400} mb={2}>
            Metals Return Policy
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            Fabricated metals that have been specially ordered or manufactured
            to your specifications, altered, cut, annealed, etc. cannot be
            returned or exchanged.
          </Typography>
          <Typography fontSize={25} fontWeight={400} mb={1} mt={5}>
            Return Policy for Bullion
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            Gold Bars, Coins, and Rounds
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            All bullion is nonreturnable and nonrefundable* after purchase.
          </Typography>

          <Typography fontSize={16} fontWeight={300} mb={2}>
            Stuller gold bars as well as gold and silver minted coins and rounds
            are eligible for the Stuller Value Exchange, which allows customers
            to trade eligible bullion for their choice of Stuller account
            credit, check, or direct deposit. The payout will be 100% of the
            exchange value based on the Second London Fix market price on the
            day it is received back at our facility. It is processed with no
            additional fees.
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            *Exceptions may be made if the pieces are defective or damaged.
          </Typography>
          <Typography fontSize={25} fontWeight={400} mb={1} mt={5}>
            Contemporary Metal Band Size Exchange Program
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            Stuller offers lifetime size exchanges for $20 per exchange on
            Contemporary Metal Bands for a different finger size of the same
            ring if available or closest similar style or value at Stuller’s
            discretion. Requires proof of purchase. Additional fees apply for
            engraving.
          </Typography>
          <Typography fontSize={25} fontWeight={400} mb={1} mt={5}>
            Product Review/Conditional Sale
          </Typography>
          <Typography fontSize={16} fontWeight={300} mb={2}>
            For accounts with Product Review/Conditional Sale privileges,
            products returned outside the Review Period will be invoiced, and
            regular return timelines and fees will apply. Please use the
            provided prepaid air bill to return items and include all original
            packaging plus a copy of the conditional sale document or invoice.
            Read the full Terms and Conditions.
          </Typography>
        </Grid>
      </Grid>
      <Box textAlign={"Center"} width={"100%"} mt={7}>
        <Typography fontSize={20} fontWeight={500} mb={1}>
          We're here to help
        </Typography>
        <Typography fontSize={20} fontWeight={300} mb={1}>
          Our Customer Care Team is ready to assist you every step of the way
        </Typography>
        <Typography fontSize={40} fontWeight={300}>
          800-345-6666
        </Typography>
      </Box>
    </>
  );
};

export default Return;
