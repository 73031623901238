import { Grid } from "@mui/material";
import BreadcrumbsPage from "../../components/common/breadcrumbs";
import React, { useState } from "react";
import CustomizeStepper from "../../components/trade/stepper";
import Features from "../../components/trade/features";
import ProductAdvancedDetail from "../../components/trade/productAdvanceDetail.js";
import Consider from "../../components/trade/consider/index.js";
import MostViewed from "../../components/trade/mostViewed/index.js";
import ProductDetail from "../../components/trade/productDetail/index.js";

export function TradePage(props) {
  const [activeTab, setactiveTab] = useState(0);

  const handleTabChange = (value) => {
    setactiveTab(value);
  };

  return (
    <Grid container gap={4}>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <BreadcrumbsPage
          breadcrumbs={[
            { label: "HOME", href: "/" },
            { label: "PRODUCTS", href: "/products" },
            { label: "ACCENTED ENGAGEMENT RING", href: "/trade" },
          ]}
        />
      </Grid>
      <Grid
        container
        px={2}
        maxWidth="1580px"
        mx="auto"
        justifyContent="center">
        <CustomizeStepper activeStep={activeTab} onTabChange={handleTabChange} />
      </Grid>
      <Grid
        container
        px={2}
        maxWidth="1580px"
        mx="auto"
        justifyContent="center"
        borderBottom="1px solid #E0E0E0">
        <ProductDetail onTabChange={handleTabChange} activeTab={activeTab} />
      </Grid>
      <Grid
        container
        px={2}
        maxWidth="1580px"
        mx="auto"
        justifyContent="center"
        borderBottom="1px solid #E0E0E0">
        <ProductAdvancedDetail />
      </Grid>
      <Grid
        container
        px={2}
        maxWidth="1580px"
        mx="auto"
        justifyContent="center"
        borderBottom="1px solid #E0E0E0"
        py={3}>
        <Consider />
      </Grid>
      <Grid
        container
        px={2}
        maxWidth="1580px"
        mx="auto"
        justifyContent="center"
        borderBottom="1px solid #E0E0E0"
        py={3}>
        <MostViewed />
      </Grid>
      <Grid
        container
        px={2}
        maxWidth="1580px"
        mx="auto"
        justifyContent="center"
        py={8}>
        <Features />
      </Grid>
    </Grid>
  );
}
