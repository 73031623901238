import { Typography } from "@mui/material";

export default function SectionTitle({ title = "Section Title",xs={},...props }) {
    return (
        <Typography variant="h4" sx={{
            textTransform: "capitalize", 
            fontFamily: "Poppins",
            fontSize: "30px",
            fontWeight: 500,
            lineHeight: "45px",
            textAlign: "center",
            ...xs
        }} gutterBottom py={6} {...props}>
            {title}
        </Typography>
    )
}