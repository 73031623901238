import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import BreadcrumbsPage from "../../components/common/breadcrumbs";

const OrderConformation = () => {
  return (
    <>
      <Grid container gap={2}>
        <Grid item px={2} maxWidth="1580px">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/placeorder" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={{ xs: 25, md: 40 }}
            fontWeight={100}
            textAlign={{ md: "center" }}
            width={"100%"}
            display={"inline-block"}
            mt={5}
            variant="p">
            Order Confirmation
          </Typography>
          <Typography
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={500}
            textAlign={{ md: "center" }}
            width={"100%"}
            display={"inline-block"}
            variant="p">
            Dispatch to #23tr463452
          </Typography>
          <Typography
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={500}
            width={"100%"}
            mt={6}
            display={"inline-block"}
            variant="p">
            <b>Hello</b> <br />
            Thank you for shopping with us. We confirmation that your item has
            shipped. Your order details are available on <br /> link below. The
            payment details of your transaction can be found on the order
            invoice
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} lg={6}>
          <Box
            mb={2}
            display={"flex"}
            flexWrap={"wrap"}
            bgcolor={"white"}
            borderRadius={"8px"}
            p={4}>
            <Box>
              <Typography
                fontSize={16}
                fontWeight={500}
                variant="p"
                mb={1}
                display={"inline-block"}>
                Your estimated delivery date is:
              </Typography>
              <Typography fontWeight={600} fontSize={16} variant="h2" mb={1}>
                Saturday, February 16, 2019 - Monday, February 18, 2019
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={500}
                variant="p"
                mb={1}
                display={"inline-block"}>
                Your shipping speed:
              </Typography>
              <Typography fontWeight={600} fontSize={16} variant="h2">
                Standard
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            mb={2}
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            bgcolor={"white"}
            borderRadius={"8px"}
            p={4}>
            <Box>
              <Typography fontWeight={600} fontSize={16} variant="h2" mb={1}>
                Payment Summary
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={500}
                variant="p"
                mb={1}
                display={"inline-block"}>
                Dispatch to #23tr463452
              </Typography>
            </Box>
            <Box>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "0",
                  overflow: "hidden",
                  boxShadow: "none",
                }}>
                <Table>
                  <TableBody>
                    <TableRow
                      sx={{
                        borderColor: "transparent",
                      }}>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                          fontWeight: 500,
                        }}>
                        Item Subtotal:
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                        }}>
                        ₹247.45
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        borderColor: "transparent",
                      }}>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                          fontWeight: 500,
                        }}>
                        Shipping & Handling:
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                        }}>
                        ₹6.35
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        borderColor: "transparent",
                      }}>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                          fontWeight: 500,
                        }}>
                        Total Before Tax:
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                        }}>
                        ₹253.80
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        borderColor: "transparent",
                      }}>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                          fontWeight: 500,
                        }}>
                        Estimated Tax:
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: "none",
                          fontSize: 16,
                        }}>
                        ₹22,84
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderConformation;
