import { Typography } from "@mui/material";

export default function ProductDetailTitle() {
  return (
    <Typography
      variant="h6"
      mr="auto"
      align="left"
      mb={1}
      fontSize={20}
      fontWeight={500}>
      14K White 9x7 mm Oval Engagement Ring Mounting
    </Typography>
  );
}
