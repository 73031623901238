import { Grid, Stack, Typography, Box } from '@mui/material';
import offer1 from '../../../assets/images/holiday/product-1.png';
import offer2 from '../../../assets/images/holiday/product-2.png';
import offer3 from '../../../assets/images/holiday/product-3.png';
import ButtonPrimary from '../../common/buttonPrimary';
import SectionTitle from '../../common/sectionTitle';

const collections = [
    { id: 1, image: offer1, discount: "Zero Down Special Financing¹", collection: "On purchases of $300 or more with the KAY Jewelers Credit Card. Promotional plan fee may apply." },
    { id: 2, image: offer2, discount: "Zero Down Special Financing¹", collection: "On purchases of $300 or more with the KAY Jewelers Credit Card. Promotional plan fee may apply." },
    { id: 3, image: offer3, discount: "Zero Down Special Financing¹", collection: "On purchases of $300 or more with the KAY Jewelers Credit Card. Promotional plan fee may apply." },
];

export function PaymentOptions(props) {
    return (
        <Grid container gap={{ md: 3 }} pb={7}>
            <Grid container maxWidth="1580px" mx="auto" textAlign="center">
                <Grid item xs={12}>
                    <SectionTitle title='Flexible Payment Options' />
                </Grid>
                <Stack direction={{ xs: "column", md: "row" }} justifyContent="center" width="100%" gap={{ xs: 4, md: 3 }} alignItems="center">
                    {collections.map((offer) => (
                        <Stack spacing={1} sx={{ width: '448px' }} px={4} direction="column" alignItems="center" justifyContent="center">
                            <img src={offer.image} alt={offer.collection} px={4} style={{ width: '201px', height: "95px" }} />
                            <Box py={{ md: 2 }}>
                                <Typography variant="h5" sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    lineHeight: "30.37px",
                                    textAlign: "center",
                                }}>{offer.discount}</Typography>
                                <Typography variant="body1" sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    lineHeight: "20.24px",
                                    textAlign: "center"
                                }}>{offer.collection}</Typography>
                            </Box>
                            <ButtonPrimary sx={{ width: "fit-content", margin: "0 auto" }}>
                                SEE DETAILS
                            </ButtonPrimary>
                        </Stack>
                    ))}
                </Stack>
            </Grid >
        </Grid >
    );
}