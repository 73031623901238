import { connect } from "react-redux";
import { profilePage } from "./profile.component";

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(profilePage);







