import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";

const TopBar = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#FFD700",
        boxShadow: "none",
        background:
          "linear-gradient(90deg, #D2A630 0%, #AB8112 49.5%, #D2A630 100%)",
        borderBottom: "10px solid #86715E",
      }}>
      <Toolbar sx={{ justifyContent: "space-between", maxWidth: "1580px" }}>
        <Link
          style={{
            color: "#fff",
            marginRight: 2,
            display: "flex",
            alignItems: "center",
            gap: "10px",
            textDecoration: "none",
          }}>
          <CallIcon />
          800-888-8888
        </Link>
        <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
          <Button
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}>
            <ShoppingCartIcon />
            Cart
          </Button>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}>
            <PersonIcon />
            <Link style={{ color: "#fff", textDecoration: "none" }}>
              Sign In
            </Link>{" "}
            /{" "}
            <Link style={{ color: "#fff", textDecoration: "none" }}>
              Create Account
            </Link>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
