import * as actionType from "./userActionType";

const initialState = {
  isLoading: false,
  isSaving: false,
  isLoggedIn: sessionStorage.getItem('loggedUser') !== null ? true : false,
  loggedUser: sessionStorage.getItem('loggedUser') !== null ? JSON.parse(sessionStorage.getItem('loggedUser')) : {},
  isSent: false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.USER_LOGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.USER_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionType.USER_FETCH:
            return {
                ...state,
                isLoading: true
            };
        case actionType.USER_FETCH_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.USER_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionType.USER_REGISTER:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.USER_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.USER_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionType.SET_IS_SAVED: 
            return {
                ...state,
                isSaved: false
            };
        case actionType.USER_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.USER_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.USER_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
                loggedUser: action.payload ? action.payload.data : {}
            };
        case actionType.FORGOT_PASSWORD:
            return {
                ...state,
                isLoading: true,
                isSent: false
            };
        case actionType.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSent: false
            };
        case actionType.RESET_PASSWORD:
            return {
                ...state,
                isLoading: true,
                isReset: false
            };
        case actionType.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReset: true
            };
        default:
            return state;
    }
}