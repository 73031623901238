import React from "react";
import { Box, Typography } from "@mui/material";
import bgImage from "../../assets/images/productreturn.png";

const ReturnBanner = () => {
  return (
    <Box
      sx={{
        mb: 7,
        position: "relative",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        width: "100%",
        height: { xs: 250, md: 500, lg: 600 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Typography
        mt={3}
        mb={3}
        fontSize={{ xs: 30, md: 50 }}
        fontWeight={700}
        color={"white"}
        textAlign={"left"}
        ml={{ xs: 5, md: 10, lg: 15 }}
        width={"100%"}>
        Product Returns
      </Typography>
    </Box>
  );
};

export default ReturnBanner;
