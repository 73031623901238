import React from "react";

// UI Component
import { Box, Button, Stack } from "@mui/material";

// Images
import ringImg from "../../assets/images/ring.png";

// Slider Libs
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules"; // Import Navigation from swiper/modules

import "swiper/css";
import "swiper/css/navigation";

// Rings Images
import img1 from "../../assets/images/rings/ring-4-1.svg";
import img2 from "../../assets/images/rings/ring-4-2.svg";
import img3 from "../../assets/images/rings/ring-4.svg";

const ImageCase = () => {
  const data = [img1, img2, img3, img1, img2, img3, img1, img2, img3];
  return (
    <Stack bgcolor="#F3F3F3" p={4} pt={7} width="100%" height="100%">
      <Box
        component="img"
        src={ringImg}
        alt="Main product image of a ring"
        sx={{
          width: { xs: "100%", sm: "50%", md: "50%" },
          margin: " 0 auto 40px",
        }}
      />
      <Box>
        <Swiper
          spaceBetween={30}
          slidesPerView={5}
          loop={true}
          modules={[Navigation]}
          navigation={true}
          style={{
            width: "100%",
            padding: "5px 25px",
          }}>
          {data.map((slide, index) => (
            <SwiperSlide key={index} style={{ textAlign: "center" }}>
              <Box
                component="img"
                className="object-contain"
                src={slide}
                // bgcolor={"black"}
                alt={`slider image ${index}`}
                border={"1px solid #B88E1D"}
                borderRadius={"8px"}
                width={"100%"}
                height={"100%"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          display={"flex"}
          mt={4}
          justifyContent={"center"}
          flexWrap={"wrap"}>
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "#86715E",
              border: "1px solid #86715E",
              fontWeight: 200,
              borderRadius: "8px",
              px: 3,
              py: "5px",
            }}>
            Machined
          </Button>
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "#86715E",
              border: "1px solid #86715E",
              fontWeight: 200,
              borderRadius: "8px",
              px: 3,
              py: "5px",
              mx: 3,
            }}>
            Lab-Grown
          </Button>
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "#86715E",
              border: "1px solid #86715E",
              fontWeight: 200,
              borderRadius: "8px",
              px: 3,
              py: "5px",
            }}>
            New
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default ImageCase;
