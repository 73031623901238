import { ProductsPage } from './products.component';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    
};

const mapStateToProps = state => ({
});

export const ProductsContainer = connect(mapStateToProps, mapDispatchToProps)(ProductsPage);