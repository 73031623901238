import React from 'react';
import { Grid } from '@mui/material';
import Header from '../../common/header';
import Navlist from '../../common/navlist';
import Footer from '../../common/footer';
import TopBar from '../../common/topbar';

export default function DefaultLayout({children}) {
    return (
        <Grid container gap={3}>
            <Grid container>
                <Grid item sm={12}>
                    <TopBar />
                </Grid>
                <Grid item sm={12}>
                    <Header />
                </Grid>
                <Grid item sm={12}>
                    <Navlist />
                </Grid>
            </Grid>
            <Grid container px={2} maxWidth="1580px" mx="auto">
                {children}
            </Grid>
            <Grid container>
                <Footer />
            </Grid>
        </Grid>
    );
}