import React from "react";
import PrimaryStone from "./primaryStone";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import StoneSizeSelect from "./StoneSizeSelect";

const MountingOptions = ({ onTabChange, activeTab }) => {
  return (
    <>
      <PrimaryStone />
      <Box mt={5} mb={2} borderBottom={"1px solid #86715E"} pb={4}>
        <Typography
          variant="h6"
          marginBottom={1}
          fontSize={20}
          fontWeight={500}>
          Design Option
        </Typography>
        <FormControlLabel
          control={<Switch color="primary" value="dynamic-class-name" />}
          label="Shank Accent Stones"
        />
      </Box>
      <StoneSizeSelect />
      <Box textAlign={"right"}>
        <Button
          onClick={() => {
            onTabChange(activeTab + 1);
          }}
          mr={"auto"}
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: "#86715E",
            color: "#fff",
            borderRadius: 0,
            px: 7,
          }}>
          SET STONES
        </Button>
      </Box>
    </>
  );
};

export default MountingOptions;
