import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

const featuresData = [
    {
        title: "Flexible Collection",
        items: ["Engagement Rings", "Gemstone Rings", "Men's"]
    },
    {
        title: "Bridal",
        items: ["Bridal Mountings", "New Products"]
    },
    {
        title: "Mountings",
        items: ["Advanced Mountings Search", "New Products"]
    },
    {
        title: "Findings",
        items: ["Die-Struck Jewelry", "Semi-Set Findings", "New Products"]
    }
];

export default function Features() {
    return (
        <Grid container spacing={2}>
            {featuresData.map((feature, index) => (
                <Grid item xs={3} key={index}>
                    <Stack justifyContent="center" direction="column" alignItems="center">
                        <Typography variant="h5" mb={2} fontWeight="300">{feature.title}</Typography>
                        {feature.items.map((item, idx) => (
                            <Typography key={idx} fontWeight="500">{item}</Typography>
                        ))}
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
}