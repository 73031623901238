import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Checkbox,
  Grid,
  FormControlLabel,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const LoginPage = (props) => {
  const { userLogin, isLoggedIn, isLoading } = props;
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      <Grid container sx={{ pt: 7 }}>
        <Grid item lg={12} md={12} sm={12} sx={{ px: 5 }}>
          <Box sx={{ maxWidth: "500px", margin: "auto" }}>
            <Box>
              <Typography variant="h1"> Sign in</Typography>
              <Typography variant="h3" sx={{ mt: 2 }}>
                If you don’t have an account register <br />
                <span style={{ color: "#939393" }}> You can</span>{" "}
                <span
                  style={{ cursor: "pointer", fontWeight: 500 }}
                  onClick={() => navigate("/")}>
                  {" "}
                  Register here !
                </span>
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <form onSubmit={handleSubmit(userLogin)}>
                <TextField
                  fullWidth
                  label="Email"
                  name="username"
                  variant="outlined"
                  {...register("username", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  error={!!errors.username}
                  helperText={
                    errors.username && "Please enter a valid email address"
                  }
                />

                <TextField
                  sx={{ mt: 3 }}
                  fullWidth
                  label="Password"
                  name="password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  {...register("password", { required: true, minLength: 8 })}
                  error={!!errors.password}
                  helperText={
                    errors.password &&
                    "Password must be at least 8 characters long"
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid
                  item
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label=" Remember me"
                    />
                  </FormGroup>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}>
                    Forgot Password ?
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    mt: 3,
                  }}>
                  <Button
                    disabled={isLoading}
                    fullWidth
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{ borderRadius: "50px" }}>
                    <span type="submit">
                      {" "}
                      {isLoading ? "Wait logging in..." : "Login"}
                    </span>
                  </Button>
                  <Box id="signInDiv" sx={{ mt: 4 }}></Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
