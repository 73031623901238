import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { RoutePath } from "./../../routes";

export default function AppRoot(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <RoutePath />
    </Box>
  );
}
