import React from 'react';
import { Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '1165px', width: "100%", border: "1px solid #B88E1D", borderRadius: "10px", overflow: "hidden" }}>
            <input style={{ padding: "15px 15px", display: "block", width: "100%", border: 0, outline: 0, fontSize:"16px"}} placeholder='Search' />
            <Button variant="contained" sx={{ height: "100%", py: "12px", display: "flex", alignItems: 'center', justifyContent: "center", flexGrow: 1, borderRadius: 0, maxWidth: "100px", width: "100%" }}>
                <SearchIcon color="#fff" sx={{ color: "#fff" }} />
            </Button>
        </Box>
    );
};

export default SearchBar;