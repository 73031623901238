import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import ring1 from "../../../assets/images/rings/ring1.svg";
import ring2 from "../../../assets/images/rings/ring2.svg";
import ring3 from "../../../assets/images/rings/ring3.svg";

const Matching = ({ onTabChange, activeTab }) => {
  const data = [
    {
      image: ring1,
      title: "Mounting, Stocked Size",
    },
    {
      image: ring2,
      title: "VS G+ Full-Cut Diamonds, Stocked Size",
    },
    {
      image: ring3,
      title: "SI1 G-H Full-Cut Diamonds, Stocked Size",
    },
    {
      image: ring1,
      title: "Mounting, Stocked Size",
    },
    {
      image: ring2,
      title: "VS G+ Full-Cut Diamonds, Stocked Size",
    },
    {
      image: ring3,
      title: "SI1 G-H Full-Cut Diamonds, Stocked Size",
    },
    {
      image: ring1,
      title: "Mounting, Stocked Size",
    },
    {
      image: ring2,
      title: "VS G+ Full-Cut Diamonds, Stocked Size",
    },
    {
      image: ring3,
      title: "SI1 G-H Full-Cut Diamonds, Stocked Size",
    },
  ];

  return (
    <>
      <Typography fontSize={20} fontWeight={600} variant="h5" mb={4}>
        Matching Band  - (Series: 126937) 
      </Typography>

      <Box py={4} px={6}>
        <Grid container spacing={2}>
          {data.map((item, key) => {
            return (
              <Grid
                key={key}
                item
                xs={12}
                sm={6}
                md={12}
                lg={4}
                mb={5}
                textAlign={"center"}>
                <Box
                  component={"img"}
                  src={item.image}
                  width={"100%"}
                  bgcolor={"#F3F3F3"}
                  p={3}
                  height={200}
                  borderRadius={"8px"}
                  mb={2}
                  border={"1px solid #D2A630"}
                />
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  borderRadius={"8px"}
                  textTransform={"capitalize"}
                  textAlign={"center"}>
                  {item.title}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  borderRadius={"8px"}
                  textTransform={"capitalize"}
                  borderTop={"1px solid #B88E1D"}
                  mx={3}
                  py={1}
                  mt={2}
                  textAlign={"center"}>
                  Made To Order
                </Typography>
                <Button
                  fontSize={16}
                  fontWeight={500}
                  sx={{
                    backgroundColor: "#B88E1D",
                    color: "white",
                    borderRadius: 0,
                    textTransform: "capitalize",
                    padding: "5px 50px",
                  }}>
                  Select
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box
        mt={4}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => {
            onTabChange(activeTab - 1);
          }}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "#B88E1D !important",
            color: "white",
            borderRadius: 0,
            fontWeight: 600,
            border: "1px solid #B88E1D",
            pl: 2,
            pr: 3,
            py: "5px",
          }}>
          <i
            style={{ fontSize: "20px", lineHeight: "normal", marginRight: 10 }}
            className="ri-arrow-left-s-line"></i>{" "}
          Back
        </Button>

        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => {
            onTabChange(activeTab + 1);
          }}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "#86715E",
            color: "#fff",
            fontWeight: 600,
            borderRadius: 0,
            px: 6,
            py: "5px",
          }}>
          Add to Cart
          <i
            style={{ fontSize: "20px", lineHeight: "normal", marginLeft: 10 }}
            className="ri-arrow-right-s-line"></i>{" "}
        </Button>
      </Box>
    </>
  );
};

export default Matching;
