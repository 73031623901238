import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Stack,
  styled,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import ButtonPrimary from "../../common/buttonPrimary";
import ButtonSecondary from "../../common/buttonSecondary";

const steps = [
  "Mounting Options",
  "Set Stones",
  "Engraving Options",
  "Matching Band",
  "Locate a Jeweler",
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 9px)",
    right: "calc(50% + 9px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#B88E1D",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#B88E1D",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#B88E1D",

    backgroundColor: "#B88E1D",
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      borderColor: theme.palette.grey[800],
      backgroundColor: "#B88E1D",
    }),
  },
}));

const QontoStepIconRoot = styled("div")(({ theme }) => ({
  color: "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: "#B88E1D",
    zIndex: 1,
    fontSize: 18,
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-circle": {
    position: "relative",
    zIndex: 1,
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: "#86715E",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .QontoStepIcon-circle-active": {
    color: "#B88E1D",
    zIndex: 1,
    fontSize: 18,
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "white",
  },
  "& .QontoStepIcon-circle-inactive": {
    color: "#B88E1D",
    zIndex: 1,
    fontSize: 18,
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "#86715E",
  },
  ...theme.applyStyles("dark", {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: "#B88E1D",
      },
    },
  ],
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle">
          <div className="QontoStepIcon-completedIcon" />
        </div>
      ) : (
        <div className="QontoStepIcon-circle">
          <div
            className={`QontoStepIcon-circle-${active ? "active" : "inactive"}`}
          />
        </div>
      )}
    </QontoStepIconRoot>
  );
}

export default function CustomizeStepper({ onTabChange, activeStep }) {
  const handleNext = () => {
    if (activeStep < steps.length - 1) onTabChange(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) onTabChange(activeStep - 1);
  };
  return (
    <Stack width="100%" mb={6} position={"relative"}>
      <Stack
        gap={4}
        justifyContent="center"
        direction="column"
        alignItems="center"
        flex="1">
        <Typography variant="h4" fontWeight={300}>
          Customize Your Item
        </Typography>
        <Stack gap={4}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<QontoConnector />}
            sx={{ width: "800px" }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  sx={{ color: "#000", textWrap: "nowrap" }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={0}>
            <Button
              startIcon={
                <KeyboardArrowLeftIcon sx={{ mr: 1, color: "#B88E1D" }} />
              }
              variant="outlined"
              sx={{
                border: 0,
                color: "#000",
                "&:hover": { border: 0 },
                "&:disabled": { border: 0 },
              }}
              onClick={handleBack}
              disabled={activeStep === 0}>
              Back
            </Button>
            <Button
              endIcon={
                <KeyboardArrowRightIcon sx={{ ml: 1, color: "#B88E1D" }} />
              }
              variant="outlined"
              sx={{
                border: 0,
                color: "#000",
                "&:hover": { border: 0 },
                "&:disabled": { border: 0 },
              }}
              onClick={handleNext}
              disabled={activeStep === steps.length - 1}>
              Next
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        gap={1}
        // display={"flex"}
        direction={{ xl: "column", xs: "row" }}
        marginTop={{ xl: 0, xs: 3 }}
        justifyContent={"center"}
        position={{ xl: "absolute" }}
        style={{
          right: 0,
        }}>
        <ButtonPrimary color="primary">Log In for Pricing</ButtonPrimary>
        <ButtonSecondary variant="contained" color="secondary">
          In Stock
        </ButtonSecondary>
      </Stack>
    </Stack>
  );
}
