import { Grid } from '@mui/material';
import BreadcrumbsPage from '../../components/common/breadcrumbs';
import React from 'react';

export function ProductsPage(props) {
  return (
    <Grid container gap={3}>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <BreadcrumbsPage breadcrumbs={[{ label: "HOME", href: "/" }, { label: "PRODUCTS", href: "/products" }, { label: "ENGAGEMENT RINGS", href: "/trade" }, { label: "ENGAGEMENTS", href: "/trade" }]} />
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <h1>Product List Page</h1>
      </Grid>
    </Grid>
  );
}