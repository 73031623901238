import React from "react";
import { Grid, Typography } from "@mui/material";
import BreadcrumbsPage from "../../components/common/breadcrumbs/index.jsx";
import ProfileBlock from "./ProfileBlock.jsx";
import OrdersBlock from "./OrdersBlock.jsx";

export function profilePage(props) {
  return (
    <>
      <Grid container gap={2}>
        <Grid item px={2} maxWidth="1580px">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/profile" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={{ xs: 25, md: 40 }}
            fontWeight={100}
            textAlign={{ md: "center" }}
            width={"100%"}
            display={"inline-block"}
            mt={5}
            variant="p">
            Profile Page & Your Orders
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} lg={6} xl={4}>
          <ProfileBlock />
        </Grid>
        <Grid item xs={12} lg={6} xl={8}>
          <OrdersBlock />
        </Grid>
      </Grid>
    </>
  );
}
