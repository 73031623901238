import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import Select from "react-select";

// User Dummy Image
import userImg from "../../assets/images/user.jpeg";

// Icons Images
import phoneIcon from "../../assets/images/icons/phone.svg";
import countryIcon from "../../assets/images/icons/country.svg";
import genderIcon from "../../assets/images/icons/gender.svg";
import langIcon from "../../assets/images/icons/lang.svg";
import locationIcon from "../../assets/images/icons/location.svg";

const ProfileBlock = () => {
  const option1 = [
    { value: "1", label: "Active" },
    { value: "2", label: "In-Active" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
  ];

  const data = [
    {
      icon: phoneIcon,
      title: "Phone Number",
      value: "+91 500 452 0546",
    },
    {
      icon: countryIcon,
      title: "Country",
      value: "USA",
    },
    {
      icon: phoneIcon,
      title: "Profile Created",
      value: "12/11/2014",
    },
    {
      icon: genderIcon,
      title: "genderIcon",
      value: "Male",
    },
    {
      icon: phoneIcon,
      title: "Age",
      value: "46",
    },
    {
      icon: langIcon,
      title: "Language",
      value: "English",
    },
    {
      icon: locationIcon,
      title: "Address",
      value: "XYZ, Address",
    },
  ];
  return (
    <Box mb={2} bgcolor={"white"} borderRadius={"8px"} px={4} py={8}>
      <Box display={"flex"} flexWrap={"wrap"}>
        <Box
          component="img"
          src={userImg}
          style={{ objectFit: "cover" }}
          alt={"round"}
          width={"100%"}
          height="160px"
          maxWidth={"160px"}
          borderRadius={10}
          mb={{ xs: 1, md: 0 }}
          marginRight={{ xs: "auto", sm: 2 }}
          marginLeft={{ xs: "auto", sm: 0 }}
        />
        <Box pt={3}>
          <Typography fontWeight={600} fontSize={20} variant="h2" mb={1}>
            Ayak Rati
          </Typography>
          <Typography fontSize={16} variant="p" mb={1} display={"inline-block"}>
            Ayak.rati96@gmail.com
          </Typography>
        </Box>
      </Box>
      <Box
        mt={3}
        mb={5}
        borderBottom="1px solid #86715E"
        pb={5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Select
          style={{ width: "100%" }}
          className="border-0"
          placeholder="Select Status"
          options={option1}
        />
        <i
          style={{
            fontSize: 20,
            color: "#86715E",
            lineHeight: "normal ",
          }}
          className="ri-edit-box-line"></i>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "0", overflow: "hidden", boxShadow: "none" }}>
        <Table>
          <TableBody>
            {data.map((item, key) => {
              return (
                <TableRow
                  key={key}
                  sx={{
                    borderColor: "transparent",
                  }}>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    <Box
                      component="img"
                      src={item.icon}
                      style={{ objectFit: "contain" }}
                      alt={"round"}
                      width={"100%"}
                      height="25px"
                      maxWidth={"25px"}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                      fontSize: 16,
                      fontWeight: 500,
                    }}>
                    {item.title}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                      fontSize: 16,
                    }}>
                    {item.value}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProfileBlock;
