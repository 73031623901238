// src/components/trade/productDetail.js/index.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";

export default function ProductAdvancedDetail() {
  const data = [
    { quantity: 1, stone: "OVAL", size: "9.00X7.00", settingType: "Prong" },
    { quantity: 14, stone: "ROUND", size: "1.70", settingType: "Pinpoint" },
    { quantity: 20, stone: "ROUND", size: "1.00", settingType: "Pinpoint" },
    { quantity: 10, stone: "ROUND", size: "0.80", settingType: "Pinpoint" },
  ];

  return (
    <Box width="100%">
      <Box borderBottom="1px solid #E0E0E0" py={4}>
        <Typography
          variant="h6"
          mr="auto"
          align="left"
          mb={2}
          fontSize={16}
          fontWeight={600}>
          Additional Details
        </Typography>
        <Typography variant="body2" bgcolor="white" px={8} py={1}>
          Out of Stock and Special Order items will ship when available.
        </Typography>
      </Box>
      <Box py={4}>
        <Typography
          variant="h6"
          mr="auto"
          align="left"
          mb={3}
          fontSize={16}
          fontWeight={600}>
          Can Be Set With (Stones Sold Separately)
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "0", overflow: "hidden", boxShadow: "none" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}>
                  Quantity
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}>
                  Stone
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}>
                  Size
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}>
                  Setting Type
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 !== 0 ? "white" : "#F9F9F9",
                    borderColor: "transparent",
                  }}>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}>
                    {row.quantity}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}>
                    {row.stone}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}>
                    {row.size}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}>
                    {row.settingType}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}>
                    View Available Gemstones
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}>
                    View Available Diamonds
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
