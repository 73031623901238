import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonSecondary from "../../common/buttonSecondary";

import Stone1Img from "../../../assets/images/stone1.svg";
import Stone2Img from "../../../assets/images/pngtree-round-brilliant-cut-diamond-perspective-isolated-bridal-picture-image_13062833.svg";

const SetStones = ({ onTabChange, activeTab }) => {
  const data = [
    {
      type: "Diamond",
      cut: "Full Cut",
      color: "G-H",
      quality: "SI2-SI3",
      estimatedPrice: "",
      stone: "Set 49 Stones",
    },
  ];

  return (
    <>
      <Box mt={5} mb={2} borderBottom={"1px solid #86715E"} pb={4}>
        <Typography
          variant="h6"
          marginBottom={1}
          fontSize={20}
          fontWeight={500}>
          Stone Options (optional)
        </Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography
            variant="p"
            marginBottom={0}
            fontSize={16}
            fontWeight={400}>
            Location
          </Typography>
          <ButtonSecondary mr={"auto"} variant="contained" color="secondary">
            SET
          </ButtonSecondary>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={2}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography
              variant="p"
              marginBottom={0}
              fontSize={16}
              fontWeight={600}>
              Center
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              ml={5}>
              <Box
                component="img"
                className="object-contain"
                src={Stone1Img}
                alt={`stone image`}
                width={40}
                height={40}
              />
              <Typography
                variant="p"
                marginBottom={0}
                ml={2}
                fontSize={16}
                fontWeight={600}>
                10.00 mm x 5.00 mm
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="p"
            marginBottom={0}
            fontSize={16}
            fontWeight={400}>
            Use My Stone
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6} sm={4} md={6} lg={4} xl={3}>
              <Button
                variant={"contained"}
                size={"large"}
                sx={{
                  boxShadow: "none",
                  textTransform: "capitalize",
                  backgroundColor: "#86715E",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: 0,
                  width: "100%",
                  py: "3px",
                }}>
                Shape & Size
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={6} lg={4} xl={3}>
              <Button
                variant={"contained"}
                size={"large"}
                sx={{
                  boxShadow: "none",
                  textTransform: "capitalize",
                  backgroundColor: "#86715E",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: 0,
                  width: "100%",
                  py: "3px",
                }}>
                Stone Location
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={4} xl={3}>
              <Button
                variant={"contained"}
                size={"large"}
                sx={{
                  boxShadow: "none",
                  textTransform: "capitalize",
                  backgroundColor: "#B88E1D",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: 0,
                  width: "100%",
                  py: "3px",
                }}>
                Stone Type
              </Button>
            </Grid>
          </Grid>
          <Box
            bgcolor={"White"}
            py={{ md: 5, xs: 1 }}
            px={{ md: 7, xs: 1 }}
            borderRadius={1}
            mt={4}>
            <Grid container spacing={2}>
              {Array(35)
                .fill(1)
                .map((item, key) => {
                  return (
                    <Grid item key={key} xs={6} sm={3} md={4} lg={3} xl={2}>
                      <Box
                        bgcolor={"#F3F3F3"}
                        textAlign={"center"}
                        borderRadius={"4px"}
                        mx={"auto"}
                        padding={"15px 8px 8px"}>
                        <Box
                          component="img"
                          display={"block"}
                          className="object-contain"
                          src={Stone2Img}
                          alt={`stone image`}
                          width={67}
                          height={89}
                          mx={"auto"}
                        />
                        <Typography
                          variant="p"
                          mb={0}
                          mt={2}
                          backgroundColor="#F9F9F9"
                          width={"100%"}
                          display={"inline-block"}
                          py={1}
                          borderRadius={"4px"}
                          fontSize={12}
                          fontWeight={400}>
                          Diamond
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box mt={5} mb={4} borderBottom={"1px solid #86715E"} pb={4}>
        <Typography
          variant="h6"
          marginBottom={1}
          fontSize={20}
          fontWeight={500}>
          Quick Set Options
        </Typography>
        <Typography
          variant="p"
          marginBottom={3}
          fontSize={16}
          fontWeight={400}
          display={"block"}>
          Set all 49 side/accent stone locations with diamonds
        </Typography>

        <Button
          variant={"contained"}
          size={"large"}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "#86715E",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "8px",
            px: 6,
            py: "5px",
            mr: 3,
          }}>
          Natural Diamonds
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "white",
            color: "#B88E1D",
            borderRadius: "8px",
            fontWeight: 600,
            border: "1px solid #B88E1D",
            px: 2,
            py: "5px",
          }}>
          <Typography
            variant="p"
            height={26}
            width={26}
            bgcolor={"#B88E1D"}
            color={"White"}
            borderRadius={10}
            fontSize={12}
            fontWeight={600}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mr={2}>
            LG
          </Typography>
          Lab-Grown Diamonds
        </Button>

        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "0",
            mt: 4,
            overflow: "hidden",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: "none",
                  }}>
                  Type
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: "none",
                  }}>
                  Cut
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: "none",
                  }}>
                  Color
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: "none",
                  }}>
                  Quality
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: "none",
                  }}>
                  Est. Price
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: "none",
                  }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 !== 0 ? "#F9F9F9" : "white",
                    borderColor: "transparent",
                  }}>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    {row.type}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    {row.cut}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    {row.color}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    {row.quality}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    {row.estimatedPrice}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      border: "none",
                    }}>
                    {row.stone}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box textAlign={"right"} mt={3}>
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
              backgroundColor: "transparent !important",
              color: "#86715E",
              borderRadius: 0,
              fontWeight: 600,
              border: "1px solid #86715E",
              px: 5,
              py: "5px",
            }}>
            View All Stone Options
          </Button>
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => {
            onTabChange(activeTab - 1);
          }}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "transparent !important",
            color: "#86715E",
            borderRadius: 0,
            fontWeight: 600,
            border: "1px solid #86715E",
            pl: 2,
            pr: 3,
            py: "5px",
          }}>
          <i
            style={{ fontSize: "20px", lineHeight: "normal", marginRight: 10 }}
            className="ri-arrow-left-s-line"></i>{" "}
          Back
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => {
            onTabChange(activeTab + 1);
          }}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "#86715E",
            color: "#fff",
            fontWeight: 600,
            borderRadius: 0,
            px: 6,
            py: "5px",
          }}>
          Engraving Options
          <i
            style={{ fontSize: "20px", lineHeight: "normal", marginLeft: 10 }}
            className="ri-arrow-right-s-line"></i>{" "}
        </Button>
      </Box>
    </>
  );
};

export default SetStones;
