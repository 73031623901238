import { Grid } from '@mui/material';
import CategoriesManager from '../../components/home/categories';
import CategoriesVariant from '../../components/home/categoriesVariant';
import BreadcrumbsPage from '../../components/common/breadcrumbs';
import React from 'react';
import OfferCard from '../../components/home/offerCard';
import banner from '../../assets/images/banner.png';
import offer1 from '../../assets/images/offers/offer-1.png';
import offer2 from '../../assets/images/offers/offer-2.png';
import offer3 from '../../assets/images/offers/offer-3.png';
import offer4 from '../../assets/images/offers/offer-4.png';
import offer5 from '../../assets/images/offers/offer-5.png';
import { HolidayCollections } from '../../components/home/holidayCollections';
import { PaymentOptions } from '../../components/home/paymentOptions';
import Testimonials from '../../components/home/testimonials';

export function DashboardPage() {
  return (
    <Grid container gap={3}>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <BreadcrumbsPage breadcrumbs={[{ label: "HOME", href: "/" }, { label:"PRODUCTS",href:"/products"}]} />
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <Grid item sm={12}>
          <img
            src={banner}
            alt="Making Every Day Classy!"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <CategoriesManager />
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto" gap={3} wrap={{ md: 'nowrap' }} direction={{ xs: "column", md: "row" }}>
        <Grid item md={5} xs={12}>
          <OfferCard
            title="Up to 40% OFF* EVERYTHING"
            subtitle="SHOP NOW"
            imageUrl={offer1}
          />
        </Grid>
        <Grid item md={7} xs={12}>
          <OfferCard
            title="Up to 40% OFF* EVERYTHING"
            subtitle="SHOP NOW"
            imageUrl={offer2}
          />
        </Grid>
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto" gap={3} wrap='nowrap' direction={{ xs: "column", md: "row" }}>
        <Grid item md={4} xs={12}>
          <OfferCard
            title="Up to 40% OFF* EVERYTHING"
            subtitle="SHOP NOW"
            imageUrl={offer3}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <OfferCard
            title="Up to 40% OFF* EVERYTHING"
            subtitle="SHOP NOW"
            imageUrl={offer4}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <OfferCard
            title="Up to 40% OFF* EVERYTHING"
            subtitle="SHOP NOW"
            imageUrl={offer5}
          />
        </Grid>
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <CategoriesVariant />
      </Grid>
      {/*
      <Grid container px={2} maxWidth="1580px" mx="auto" direction={{ xs: "column", md: "row" }}>
        <GiftPicksSection />
      </Grid>
      */}
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <HolidayCollections />
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <Testimonials />
      </Grid>
      <Grid container px={2} maxWidth="1580px" mx="auto">
        <PaymentOptions />
      </Grid>
    </Grid>
  );
}