import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import TrackingBlock from "./TrackingBlock";

const OrdersBlock = () => {
  return (
    <>
      <Typography
        fontSize={20}
        fontWeight={600}
        bgcolor={"white"}
        py={3}
        px={6}
        mb={3}>
        Orders
      </Typography>
      {Array(10)
        .fill(1)
        .map((item, key) => {
          return (
            <Accordion
              key={key}
              style={{
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                boxShadow: "none",
                marginBottom: 20,
              }}>
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header">
                <Grid container spacing={2} py={2} px={4}>
                  <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      display={"block"}
                      mb={"3px"}>
                      Order Placed
                    </Typography>
                    <Typography fontSize={16} fontWeight={500}>
                      23 November 2024
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={6} xl={2}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      display={"block"}
                      mb={"3px"}>
                      Total
                    </Typography>
                    <Typography fontSize={16} fontWeight={500}>
                      ₹2 25 568
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      display={"block"}
                      mb={"3px"}>
                      Dispatch to
                    </Typography>
                    <Typography fontSize={16} fontWeight={500}>
                      26 November 2024
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      display={"block"}
                      mb={"3px"}>
                      Dispatch to #23tr463452
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={"#B88E1D"}
                      display={"flex"}
                      alignItems={"center"}>
                      View Order Details
                      <i
                        style={{
                          fontSize: 20,
                          lineHeight: "normal",
                          marginLeft: 3,
                        }}
                        className="ri-arrow-down-s-line"></i>
                      <span style={{ marginLeft: 20, display: "inline-block" }}>
                        Invoice
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  paddingRight: 45,
                  paddingLeft: 45,
                  paddingBottom: 40,
                }}>
                <Stack
                  style={{
                    borderTop: " 1px solid #86715E",
                    marginBottom: 40,
                  }}></Stack>
                <Typography>
                  <TrackingBlock />
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
};

export default OrdersBlock;
