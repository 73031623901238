import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import round from "../../../assets/images/shape/round.svg";
import oval from "../../../assets/images/shape/oval.svg";
import square from "../../../assets/images/shape/square.svg";
import emerald from "../../../assets/images/shape/emerald.svg";
import pear from "../../../assets/images/shape/pear.svg";
import marquise from "../../../assets/images/shape/marquise.svg";
import cushion from "../../../assets/images/shape/cushion.svg";
import asscher from "../../../assets/images/shape/asscher.svg";
import heart from "../../../assets/images/shape/heart.svg";

export default function PrimaryStone() {
  const data = [
    { title: "round", image: round },
    { title: "oval", image: oval },
    { title: "square", image: square },
    { title: "emerald", image: emerald },
    { title: "pear", image: pear },
    { title: "marquise", image: marquise },
    { title: "cushion", image: cushion },
    { title: "asscher", image: asscher },
    { title: "heart", image: heart },
  ];

  return (
    <Box mb={2} borderBottom={"1px solid #86715E"} pb={4}>
      <Typography variant="h6" marginBottom={2} fontSize={20} fontWeight={500}>
        Primary Stone Shape
      </Typography>
      <Grid container spacing={2}>
        {data.map((item, key) => (
          <Grid item xs={2} xl={1} key={key}>
            <Box textAlign="center">
              <Typography
                variant="p"
                key={key}
                display={"inline-block"}
                width={"100%"}
                textAlign="center"
                textTransform={"capitalize"}
                marginBottom={2}>
                {item.title}
              </Typography>
              <Box
                component="img"
                className="object-contain"
                src={item.image}
                alt={item.title}
                width="50px"
                height="50px"
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* <Stack direction="row" spacing={2} flexWrap="wrap">
        {data.map((item, key) => (
          <Box textAlign="center">
            <Typography
              variant="p"
              key={key}
              display={"inline-block"}
              width={"100%"}
              textAlign="center"
              textTransform={"capitalize"}
              marginBottom={2}>
              {item.title}
            </Typography>
            <Box
              component="img"
              className="object-contain"
              src={item.image}
              alt={item.title}
              width="50px"
              height="50px"
            />
          </Box>
        ))}
      </Stack> */}
    </Box>
  );
}
