import { Box, Typography } from "@mui/material";
import React from "react";

const StoreLocation = () => {
  const data = [
    {
      icon: "ri-map-pin-2-line",
      title: "Location",
      subTitle: "302 Rue Louis XIV Lafayette, LA 70508",
    },
    {
      icon: "ri-mail-line",
      title: "Mailing Address",
      subTitle: "P.O. Box 87777 Lafayette, LA 70508",
    },
    {
      icon: "ri-phone-line",
      title: "Phone Number",
      subTitle: "800 345 6666",
    },
  ];
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        borderBottom={" 1px solid #86715E"}
        pb={10}
        mb={10}
        mt={10}>
        <Box
          bgcolor={"#D9D9D9"}
          borderRadius={6}
          height={64}
          width={64}
          mr={4}
        />
        <Box>
          <Typography fontSize={30} fontWeight={300} mb={1}>
            U.S. Customer Care
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            Monday - Friday 8:00 a.m. - 7:00 p.m. Central
          </Typography>
          <Typography fontSize={14} fontWeight={300}>
            English-speaking and Spanish-speaking associates
          </Typography>
          {data.map((item, key) => {
            return (
              <Box key={key} display={"flex"} mt={3}>
                <i
                  style={{ color: "#B88E1D", fontSize: 22, marginRight: 15 }}
                  className={item.icon}></i>
                <Box>
                  <Typography fontSize={16} fontWeight={400} mb={"4px"}>
                    {item.title}
                  </Typography>
                  <Typography fontSize={14} fontWeight={300} width={"180px"}>
                    {item.subTitle}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
        <Box
          bgcolor={"#D9D9D9"}
          borderRadius={6}
          height={64}
          width={64}
          mr={4}
        />
        <Box>
          <Typography fontSize={30} fontWeight={300} mb={1}>
            U.S. Customer Care
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            Monday - Friday 8:00 a.m. - 7:00 p.m. Central
          </Typography>
          <Typography fontSize={14} fontWeight={300}>
            English-speaking and Spanish-speaking associates
          </Typography>
          {data.map((item, key) => {
            return (
              <Box key={key} display={"flex"} mt={3}>
                <i
                  style={{ color: "#B88E1D", fontSize: 22, marginRight: 15 }}
                  className={item.icon}></i>
                <Box>
                  <Typography fontSize={16} fontWeight={400} mb={"4px"}>
                    {item.title}
                  </Typography>
                  <Typography fontSize={14} fontWeight={300} width={"180px"}>
                    {item.subTitle}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default StoreLocation;
