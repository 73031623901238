import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Select from "react-select";

const StoneSizeSelect = () => {
  const option1 = [
    { value: "1", label: "19 x 7 mm" },
    { value: "2", label: "9 x 7 mm" },
    { value: "3", label: "9 x 7 mm" },
  ];
  const option2 = [
    { value: "1", label: "14K White" },
    { value: "2", label: "Strawberry" },
    { value: "3", label: "Vanilla" },
  ];
  const option3 = [
    { value: "1", label: "7.00 (approximate standard size)" },
    { value: "2", label: "Strawberry" },
    { value: "3", label: "Vanilla" },
  ];

  return (
    <Box mt={5} mb={5} borderBottom={"1px solid #86715E"} pb={6}>
      <Typography variant="h6" marginBottom={1} fontSize={20} fontWeight={500}>
        Primary Stone Size
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Select placeholder="Select size" options={option1} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Select placeholder="Select Color" options={option2} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Select placeholder="Select cents" options={option3} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoneSizeSelect;
