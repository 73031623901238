import { Typography, Box, Card, CardContent } from "@mui/material";
import testimonial1 from "../../../assets/images/consider/ring1.png";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import React, { useCallback, useState } from "react";

const testimonials = [
  {
    id: 1,
    text: "Accented Engagement Ring",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
  {
    id: 2,
    text: "Halo-Style Engagement Ring",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
  {
    id: 3,
    text: "Accented Engagement Ring",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
  {
    id: 4,
    text: "Hidden Halo-Style Engagement Ring or Band",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
  {
    id: 5,
    text: "Hidden Halo-Style Engagement Ring",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
  {
    id: 6,
    text: "Accented Engagement Ring",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
  {
    id: 7,
    text: "Accented Engagement Ring",
    author: "Alexis JM",
    image: testimonial1, // Replace with actual image path
  },
];

export default function Consider() {
  const [swiperRef, setSwiperRef] = useState(null);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        mr="auto"
        align="left"
        mb={3}
        fontSize={16}
        fontWeight={600}>
        More Items to Consider
      </Typography>
      <Box width="100%" overflow="hidden" display="flex">
        <div
          style={{
            height: "220px",
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
          }}>
          <KeyboardArrowRightIcon
            onClick={handlePrevious}
            fontSize="large"
            sx={{
              rotate: "180deg",
              color: "#B88E1D",
              fontSize: "40px",
              cursor: "pointer",
            }}
          />
        </div>
        <Swiper
          slidesPerView={5}
          spaceBetween={20}
          loop={true}
          onSwiper={setSwiperRef}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: "auto",
              spaceBetween: 10,
            },
            375: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            450: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1130: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1650: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1800: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
          className="SliderShopByCategory">
          {Array.from(testimonials)?.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <Card
                sx={{
                  boxShadow: "none",
                  bgcolor: "transparent",
                  borderRadius: 0,
                }}>
                <div
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #D2A630",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                    backgroundColor: "#F3F3F3",
                  }}>
                  <img
                    src={testimonial.image}
                    alt={`Testimonial ${testimonial.id}`}
                    style={{
                      width: "100%",
                      height: "120px",
                      maxWidth: "298px",
                      maxHeight: "120px",
                      overflow: "hidden",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <CardContent>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ color: "#000000    " }}>
                    {testimonial.text}
                  </Typography>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          style={{
            height: "220px",
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
          }}>
          <KeyboardArrowRightIcon
            onClick={handleNext}
            fontSize="large"
            sx={{ color: "#B88E1D", fontSize: "40px", cursor: "pointer" }}
          />
        </div>
      </Box>
    </React.Fragment>
  );
}
