import * as actionTypes from "./userActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
const { REACT_APP_API_URL } = process.env;

export const userLogin = (data) => {
    console.log("userlogin: ",data);
    
      return (dispatch) => {
        dispatch(userLoginInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/user/authenticate`, data))
        .then((response) => {
            setLoginSession(response.data.data);
            dispatch(userLoginSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(userLoginFailure());
            checkError(error);
        })
    };
};

export const userLoginInit = () => ({
    type: actionTypes.USER_LOGIN
});

export const userLoginFailure = () => ({
    type: actionTypes.USER_LOGIN_FAILURE
});

export const userLoginSuccess = data => ({
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: { data }
});

export const userRegister = (data) => {
    // const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(userRegisterInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/user/register`, data))
        .then((response) => {
            setLoginSession(response.data.data);
            dispatch(userRegisterSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(userRegisterFailure());
            checkError(error);
        })
    };
};

export const userRegisterInit = () => ({
    type: actionTypes.USER_REGISTER
});

export const userRegisterFailure = () => ({
    type: actionTypes.USER_REGISTER_FAILURE
});

export const userRegisterSuccess = data => ({
    type: actionTypes.USER_REGISTER_SUCCESS,
    payload: { data }
});

export const userUpdate = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("userToken")}` } };
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });  
    return (dispatch) => {
        dispatch(userUpdateInit());
        apiTimeout(axios.put(`${REACT_APP_API_URL}/user`, params, config))
        .then((response) => {
            setLoginSession(response.data.data);
            dispatch(userUpdateSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(userUpdateFailure());
            checkError(error);
        })
    };
};

export const setIsSaved = (data) => {
    return (dispatch) => {
        dispatch(setIsSavedInit());
    }
};

export const setIsSavedInit = () => ({
    type: actionTypes.SET_IS_SAVED
});

export const userUpdateInit = () => ({
    type: actionTypes.USER_UPDATE
});

export const userUpdateFailure = () => ({
    type: actionTypes.USER_UPDATE_FAILURE
});

export const userUpdateSuccess = data => ({
    type: actionTypes.USER_UPDATE_SUCCESS,
    payload: { data }
});

export const userFetch = (token=sessionStorage.getItem("userToken")) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return (dispatch) => {
      dispatch(userFetchInit());
      apiTimeout(axios.get(`${REACT_APP_API_URL}/user/current`, config))
      .then((response) => {
          setLoginSession(response.data.data);
          dispatch(userFetchSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(userFetchFailure());
          checkError(error);
      })
  };
};

export const userFetchInit = () => ({
  type: actionTypes.USER_FETCH
});

export const userFetchFailure = () => ({
  type: actionTypes.USER_FETCH_FAILURE
});

export const userFetchSuccess = data => ({
  type: actionTypes.USER_FETCH_SUCCESS,
  payload: { data }
});

//Helper Function
function setLoginSession(data) {
    sessionStorage.setItem("loggedUser", JSON.stringify(data));
    sessionStorage.setItem("userToken", data.token);
}

export const forgotPassword = (data) => {
    return (dispatch) => {
      dispatch(forgotPasswordInit());
      apiTimeout(axios.post(`${REACT_APP_API_URL}/user/forgot-password`, data))
      .then((response) => {
          setLoginSession(response.data.data);
          dispatch(forgotPasswordSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(forgotPasswordFailure());
          checkError(error);
      })
  };
};

export const forgotPasswordInit = () => ({
  type: actionTypes.FORGOT_PASSWORD
});

export const forgotPasswordFailure = () => ({
  type: actionTypes.FORGOT_PASSWORD_FAILURE
});

export const forgotPasswordSuccess = data => ({
  type: actionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: { data }
});

export const resetPassword = (data) => {
    const config = { headers: { Authorization: `Bearer ${data.token}` } };
    return (dispatch) => {
      dispatch(resetPasswordInit());
      apiTimeout(axios.post(`${REACT_APP_API_URL}/user/reset-password`, data, config))
      .then((response) => {
          setLoginSession(response.data.data);
          dispatch(resetPasswordSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(resetPasswordFailure());
          checkError(error);
      })
  };
};

export const resetPasswordInit = () => ({
  type: actionTypes.RESET_PASSWORD
});

export const resetPasswordFailure = () => ({
  type: actionTypes.RESET_PASSWORD_FAILURE
});

export const resetPasswordSuccess = data => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: { data }
});