import { Button } from "@mui/material";

export default function ButtonSecondary({
  children,
  sx = {},
  variant = "outlined",
  size = "large",
  borderRadius = 0,
  ...props
}) {
  return (
    <Button
      variant={variant}
      size={size}
      sx={{
        backgroundColor: "#86715E",
        color: "#fff",
        borderRadius: borderRadius,
        px: 6,
        ...sx,
      }}
      {...props}>
      {children}
    </Button>
  );
}
