import { connect } from "react-redux";
import { placeorderPage } from "./placeorder.component";

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export const PlaceorderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(placeorderPage);
