import { DashboardPage } from './dashboard.component';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    
};

const mapStateToProps = state => ({
});

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);