import { Box, Grid, Typography } from "@mui/material";
import BreadcrumbsPage from "../../components/common/breadcrumbs/index.jsx";
import React from "react";

import ringImg from "../../assets/images/ring.png";
import Select from "react-select";

export function placeorderPage(props) {
  const option1 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
  ];
  return (
    <>
      <Grid container gap={2}>
        <Grid item px={2} maxWidth="1580px">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/placeorder" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={{ xs: 25, md: 40 }}
            fontWeight={100}
            textAlign={{ md: "center" }}
            width={"100%"}
            display={"inline-block"}
            mt={5}
            variant="p">
            Cart
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} lg={6}>
          <Box
            mb={2}
            display={"flex"}
            flexWrap={"wrap"}
            bgcolor={"white"}
            borderRadius={"8px"}
            p={2}>
            <Box
              component="img"
              src={ringImg}
              style={{ objectFit: "contain" }}
              alt={"round"}
              width={"100%"}
              height="200px"
              maxWidth={"200px"}
              p={2}
              border="1px solid #B88E1D"
              borderRadius={"8px"}
              mb={{ xs: 1, md: 0 }}
              marginRight={{ xs: "auto", sm: 2 }}
              marginLeft={{ xs: "auto", sm: 0 }}
            />
            <Box>
              <Typography
                fontSize={16}
                variant="p"
                mb={1}
                display={"inline-block"}>
                Glowy Diamond Vanki Ring
              </Typography>
              <Typography fontWeight={600} fontSize={20} variant="h2" mb={1}>
                ₹19,061
              </Typography>
              <Typography
                fontSize={12}
                variant="p"
                mb={1}
                display={"inline-block"}>
                JR07688-1YP900
              </Typography>
              <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography fontWeight={600} fontSize={16} variant="p">
                    Size:
                  </Typography>
                  <Select
                    style={{ width: "100%" }}
                    className="border-0"
                    placeholder="Select Size"
                    options={option1}
                  />
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography fontWeight={600} fontSize={16} variant="p">
                    Quantity:
                  </Typography>
                  <Select
                    style={{ width: "100%" }}
                    className="border-0"
                    placeholder="Select Quantity"
                    options={option1}
                  />
                </Box>
              </Box>
              <Typography fontSize={16} variant="p">
                Check Delivery Date
              </Typography>
            </Box>
          </Box>
          <Box
            mb={2}
            display={"flex"}
            flexWrap={"wrap"}
            bgcolor={"white"}
            borderRadius={"8px"}
            p={2}>
            <Box
              component="img"
              src={ringImg}
              style={{ objectFit: "contain" }}
              alt={"round"}
              width={"100%"}
              height="200px"
              maxWidth={"200px"}
              p={2}
              border="1px solid #B88E1D"
              borderRadius={"8px"}
              mb={{ xs: 1, md: 0 }}
              marginRight={{ xs: "auto", sm: 2 }}
              marginLeft={{ xs: "auto", sm: 0 }}
            />
            <Box>
              <Typography
                fontSize={16}
                variant="p"
                mb={1}
                display={"inline-block"}>
                Glowy Diamond Vanki Ring
              </Typography>
              <Typography fontWeight={600} fontSize={20} variant="h2" mb={1}>
                ₹19,061
              </Typography>
              <Typography
                fontSize={12}
                variant="p"
                mb={1}
                display={"inline-block"}>
                JR07688-1YP900
              </Typography>
              <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography fontWeight={600} fontSize={16} variant="p">
                    Size:
                  </Typography>
                  <Select
                    style={{ width: "100%" }}
                    className="border-0"
                    placeholder="Select Size"
                    options={option1}
                  />
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography fontWeight={600} fontSize={16} variant="p">
                    Quantity:
                  </Typography>
                  <Select
                    style={{ width: "100%" }}
                    className="border-0"
                    placeholder="Select Quantity"
                    options={option1}
                  />
                </Box>
              </Box>
              <Typography fontSize={16} variant="p">
                Check Delivery Date
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}></Grid>
      </Grid>
    </>
  );
}
