import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

// Icons Images
import deliverIcon from "../../assets/images/icons/deliver.svg";
import deliveredIcon from "../../assets/images/icons/delivered.svg";
import packedIcon from "../../assets/images/icons/packed.svg";
import transitIcon from "../../assets/images/icons/transit.svg";
import { StepConnector } from "@mui/material";

// Custom Step Icon Component
const CustomStepIcon = (props) => {
  const { active, icon } = props;

  const icons = {
    1: packedIcon,
    2: transitIcon,
    3: deliveredIcon,
    4: deliverIcon,
  };

  return (
    <Box
      sx={{
        width: 57,
        height: 57,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#B88E1D",
        boxShadow: active ? "0 4px 10px rgba(0, 0, 0, 0.2)" : "none",
      }}>
      <img
        src={icons[icon]}
        alt={`step-icon-${icon}`}
        style={{ width: 30, height: 30 }}
      />
    </Box>
  );
};

// Custom Step Connector for Centered Line
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    marginLeft: "15px", // Center line relative to icon box
    borderLeftWidth: "2px", // Thickness of the line
    borderColor: "#86715E", // Line color
  },
  "& .MuiStepContent-root": {
    marginLeft: "15px", // Center line relative to icon box
    borderLeftWidth: "2px", // Thickness of the line
    borderColor: "#86715E", // Line color
  },
}));

// Main Component
const TrackingBlock = () => {
  const steps = [
    {
      date: "23 Nov",
      label: "Packed",
      description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper `,
    },
    {
      date: "24 Nov",
      label: "At the transit center",
      description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper `,
    },
    {
      date: "25 Nov",
      label: "Being delivered",
      description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper `,
    },
    {
      date: "26 Nov",
      label: "Deliver to you",
      description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper `,
    },
  ];

  return (
    <Box width={"100%"}>
      <Stepper
        activeStep={2}
        orientation="vertical"
        connector={<CustomConnector />}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box display={"flex"}>
                <Typography fontSize={16} fontWeight={600} mr={8}>
                  {step.date}
                </Typography>
                <Typography fontSize={16} fontWeight={600}>
                  {step.label}
                </Typography>
              </Box>
            </StepLabel>
            <StepContent
              sx={{
                marginLeft: "27px",
                borderWidth: 2,
                borderColor: "#86715E",
              }}>
              <Typography pl={17} width={"100%"}>
                {step.description}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TrackingBlock;
