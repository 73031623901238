import { connect } from "react-redux";
import { setPagePage } from "./setpage.component";

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export const SetPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(setPagePage);
