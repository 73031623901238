import { Typography, Box, Card, CardContent } from '@mui/material';
import testimonial1 from '../../../assets/images/testimonials/testimonial-1.png';

import { Swiper, SwiperSlide } from "swiper/react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "swiper/css";
import "swiper/css/pagination";
import { useCallback, useState } from 'react';
import ButtonPrimary from '../../common/buttonPrimary';

const testimonials = [
    {
        id: 1,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
    {
        id: 2,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
    {
        id: 3,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
    {
        id: 4,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
    {
        id: 5,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
    {
        id: 6,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
    {
        id: 7,
        text: `"Absolutely worth every penny, my fiancé loves it. We went to get it sized to his wrist & without hesitation they did so with no extra charge."`,
        author: "Alexis JM",
        image: testimonial1, // Replace with actual image path
    },
];

export default function Testimonials() {
    const [swiperRef, setSwiperRef] = useState(null);

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);
    return (
        <div style={{ textAlign: 'center', padding: '40px 0', width: "100%", overflow: "hidden" }}>
            <Typography variant="h4" gutterBottom>
                What Our Customers Are Saying
            </Typography>
            <Typography variant="body1" gutterBottom>
                We’ve received over 200,000 5-star reviews!
            </Typography>
            <ButtonPrimary sx={{ marginBottom: '20px' }}>
                SHOP 5-STAR STYLES
            </ButtonPrimary>
            <Box width="100%" overflow="hidden" mt={4} display="flex">
                <div style={{ height: "298px", display: "flex", alignItems: "center", padding: "0 50px" }}>
                    <KeyboardArrowRightIcon onClick={handlePrevious} fontSize='large' sx={{ rotate: "180deg", color: "#B88E1D", fontSize: "40px", cursor: "pointer" }} /></div>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={20}
                    loop={true}
                    onSwiper={setSwiperRef}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 'auto',
                            spaceBetween: 10,
                        },
                        375: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        450: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1130: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1650: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1800: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    className="SliderShopByCategory"
                >
                    {Array.from(testimonials)?.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <Card sx={{ boxShadow: "none", bgcolor: "transparent", borderRadius: 0 }}>
                                <div style={{ borderRadius: "8px", border: "1px solid #D2A630", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", padding: "40px" }}>
                                     <img src={testimonial.image} alt={`Testimonial ${testimonial.id}`} style={{ width: '100%', height: "200px", maxWidth: "298px", maxHeight: "298px", overflow:"hidden", objectFit:"contain" }} />
                                </div>
                                <CardContent>
                                    <Typography variant="body2" align='left' style={{ color: "#000000    " }}>
                                        {testimonial.text}
                                    </Typography>
                                    <div style={{ marginTop: '10px', textAlign: "left", color: "#D2A630" }}>
                                        {'★ ★ ★ ★ ★'}
                                    </div>
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div style={{ height: "298px", display: "flex", alignItems: "center", padding: "0 50px" }}>
                    <KeyboardArrowRightIcon onClick={handleNext} fontSize='large' sx={{ color: "#B88E1D", fontSize: "40px", cursor: "pointer" }} /></div>
            </Box>
        </div>
    );
}
