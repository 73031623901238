import React from 'react';
import { AppBar, Toolbar, Tabs, Tab, Box } from '@mui/material';

const tabs = [
    { label: "New" },
    { label: "3C Designs" },
    { label: "Engagement Rings" },
    { label: "Wedding Bands" },
    { label: "Jewelry" },
    { label: "Mountings" },
    { label: "Chain & Cord" },
    { label: "Diamonds" },
    { label: "Lab-Grown Diamonds" },
    { label: "Gemstones" },
    { label: "Findings" },
    { label: "Metals" },
    { label: "Tools & Supplies" },
    { label: "Diamonds" },
    { label: "Lab-Grown Diamonds" },
    { label: "Gemstones" },
    { label: "Findings" },
    { label: "Metals" },
    { label: "Tools & Supplies" },
];


export default function Navlist() {
    return (
        <Box pb={1} px={2} overflow="hidden" width="100%">
            <Box sx={{ borderBottom: "2px solid #D1A52F" }} pb={1}>
                <AppBar position="static" backgroundColor="#F3F3F3" color="transparent" px={5} sx={{ boxShadow: "none", backgroundColor: "#F3F3F3", overflow: "auto" }}>
                    <Toolbar sx={{ overflow: "auto !important" }}>
                        <Tabs sx={{ overflow: "auto !important" }}>
                            {tabs.map((tab, index) => (
                                <Tab key={index} label={tab.label} sx={{
                                    color: "#000000", fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    textAlign: "left"
                                }} />
                            ))}
                        </Tabs>
                    </Toolbar>
                </AppBar>
            </Box>
        </Box>
    );
}