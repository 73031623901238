import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";

export default function Specifications() {
  // const data = [
  //   { quantity: 1, stone: "OVAL", size: "9.00X7.00", settingType: "Prong" },
  //   { quantity: 14, stone: "ROUND", size: "1.70", settingType: "Pinpoint" },
  //   { quantity: 20, stone: "ROUND", size: "1.00", settingType: "Pinpoint" },
  //   { quantity: 10, stone: "ROUND", size: "0.80", settingType: "Pinpoint" },
  //   { quantity: 1, stone: "OVAL", size: "9.00X7.00", settingType: "Prong" },
  //   { quantity: 14, stone: "ROUND", size: "1.70", settingType: "Pinpoint" },
  //   { quantity: 20, stone: "ROUND", size: "1.00", settingType: "Pinpoint" },
  //   { quantity: 10, stone: "ROUND", size: "0.80", settingType: "Pinpoint" },
  // ];

  const data = [
    {
      title: "Weight",
      value: "2.3754 DWT (3.69 grams)",
    },
    {
      title: "Solidity",
      value: "Solid",
    },
    {
      title: "Ring Back Design",
      value: "Closed Back",
    },
    {
      title: "Sold By",
      value: "Each",
    },
    {
      title: "Weight Description",
      value: "Standard Weight",
    },
    {
      title: "Surface Finish",
      value: "Polished",
    },
    {
      title: "Plating Type",
      value: "Rhodium-Plated",
    },
    {
      title: "Primary Stone Size",
      value: "9 x 7 mm",
    },
    {
      title: "Primary Stone Carat Weight",
      value: "1 1/2 CT",
    },
    {
      title: "Diamond CTW",
      value: "1 7/8 CTW",
    },
    {
      title: "Prong Count",
      value: "4-prong",
    },
    {
      title: "Primary Prong Style",
      value: "Standard Prong",
    },
    {
      title: "Approx. Top Dimensions",
      value: "9.1 mm",
    },
    {
      title: "Approx. Top Height",
      value: "6.83 mm",
    },
    {
      title: "Approx. Shoulder Width",
      value: "2.2 mm",
    },
    {
      title: "Approx. Shank Base Width",
      value: "2.2 mm",
    },
    {
      title: "Approx. Shank Base Thickness:",
      value: "1.8 mm",
    },
  ];

  return (
    <Box pt={4}>
      <Typography
        variant="h6"
        mr="auto"
        align="left"
        mb={3}
        fontSize={16}
        fontWeight={600}>
        Specifications
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "0", overflow: "hidden", boxShadow: "none" }}>
        <Table>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 !== 0 ? "#F9F9F9" : "white",
                  borderColor: "transparent",
                }}>
                <TableCell
                  style={{
                    fontSize: 16,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 50,
                    border: "none",
                  }}>
                  {row.title}:
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 16,
                    paddingTop: 5,
                    paddingBottom: 5,
                    border: "none",
                  }}>
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
