import React from 'react';
import { Grid, Button } from '@mui/material';
import SectionTitle from '../../common/sectionTitle';

const categories = [
    "ENGAGEMENT",
    "ENGAGEMENT BRIDAL SETS",
    "NECKLACES",
    "WOMEN'S NECKLACES",
    "MEN'S NECKLACES",
    "HALO ENGAGEMENT RINGS"
];

export default function CategoriesManager(){
    return (
        <React.Fragment>
            <SectionTitle width="100%" py={0} pb={2} title='Categories Inspired By You' />
            <Grid container spacing={2} pb={2} justifyContent="center" overflow="hidden">
                {categories.map((category, index) => (
                    <Grid item key={index} flexGrow={1}>
                        <Button variant="outlined" size='large' sx={{ color: '#000',backgroundColor:"white", borderRadius: 0, width:"100%"}} py={2.5}>
                            {category}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}