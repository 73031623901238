import { Typography } from "@mui/material";
import React from "react";

const ValidationMsg = ({ text }) => {
  return (
    <Typography
      color={"red"}
      type="invalid"
      className="d-block"
      textTransform={"capitalize"}
      fontSize={14}
      mt={1}>
      {text}
    </Typography>
  );
};

export default ValidationMsg;
