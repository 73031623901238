import { Grid, Stack, Typography, Box } from '@mui/material';
import offer1 from '../../../assets/images/holiday/product-1.png';
import offer2 from '../../../assets/images/holiday/product-2.png';
import offer3 from '../../../assets/images/holiday/product-3.png';
import ButtonPrimary from '../../common/buttonPrimary';
import SectionTitle from '../../common/sectionTitle';

const collections = [
    { id: 1, image: offer1, discount: "20% OFF", collection: "Milestones Collection" },
    { id: 2, image: offer2, discount: "30% OFF", collection: "Milestones Collection" },
    { id: 3, image: offer3, discount: "20% OFF", collection: "Milestones Collection" },
];

export function HolidayCollections(props) {
    return (
        <Grid container gap={3} pb={3}>
            <Grid container maxWidth="1580px" mx="auto" textAlign="center">
                <Grid item xs={12}>
                    <SectionTitle title='Save on Must-Have Holiday Collections' />
                </Grid>
                <Stack justifyContent="center" width="100%" gap={3} direction={{ xs: "column", md: "row" }}>
                    {collections.map((offer) => (
                        // <Grid item xs={4} key={offer.id}>
                        <Stack spacing={1} direction="column" alignItems="center" justifyContent="center">
                            <img src={offer.image} alt={offer.collection} style={{ width: '377px', height: "340px" }} />
                            <Box py={1}>
                            <Typography variant="h6" sx={{
                                fontFamily: "Poppins",
                                fontSize: "30px",
                                fontWeight: 500,
                                lineHeight: "30.37px",
                                textAlign: "center",
                            }}>{offer.discount}</Typography>
                            <Typography variant="body1" sx={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: 500,
                                lineHeight: "20.24px",
                                textAlign: "center"
                            }}>{offer.collection}</Typography>
                            </Box>
                            <ButtonPrimary sx={{ width: "fit-content", margin: "0 auto" }}>
                                SHOP NOW
                            </ButtonPrimary>
                        </Stack>
                        // </Grid>
                    ))}
                </Stack>
            </Grid >
        </Grid >
    );
}