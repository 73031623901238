import { TradePage } from "./trade.component";
import { connect } from "react-redux";

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export const TradeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradePage);
