import React from 'react';
import { Box, Typography, CardMedia } from '@mui/material';

export default function OfferCard({ title, subtitle, imageUrl }) {
    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', minHeight:"466px", backgroundColor:"#000" }}>
            <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    color: '#190306',
                    // textShadow: '1px 1px 2px black',
                    backgroundColor:"#FFFFFFE0",
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                }}
            >
                <Typography variant="h5" maxWidth="250px">{title}</Typography>
                <Typography variant="body">{subtitle}</Typography>
            </Box>
        </Box>
    );
}