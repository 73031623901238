import React from "react";
import { Link, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function BreadcrumbsPage({ breadcrumbs = [] }) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      {breadcrumbs.map(
        (
          breadcrumb,
          index // Map over the breadcrumbs array
        ) => (
          <Link
            key={index} // Use index as key (consider a unique identifier if available)
            underline="hover"
            color="inherit"
            href={breadcrumb.href}
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "29.44px",
              textAlign: "left",
            }}>
            {breadcrumb.label}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
}
