import { LoginPage } from './login.component';
import { connect } from 'react-redux';
import { userLogin } from './../../api/user/userAction';

const mapDispatchToProps = {
    userLogin
};

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isLoggedIn: state.userPage.isLoggedIn,
    loggedUser: state.userPage.loggedUser
});

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);