import { Box, Button, Grid, Typography } from "@mui/material";
import BreadcrumbsPage from "../../components/common/breadcrumbs/index.jsx";
import React from "react";
import ImageCase from "./ImageCase.jsx";
import round from "../../assets/images/shape/round.svg";

import Select from "react-select";

export function setPagePage(props) {
  const option1 = [
    { value: "1", label: "19 x 7 mm" },
    { value: "2", label: "9 x 7 mm" },
    { value: "3", label: "9 x 7 mm" },
  ];
  return (
    <>
      <Grid container gap={2}>
        <Grid item px={2} maxWidth="1580px">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/setpage" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={40}
            fontWeight={100}
            textAlign={"center"}
            width={"100%"}
            display={"inline-block"}
            my={5}
            variant="p">
            Dual-Tone Halo Diamond Finger Ring
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}>
            <Box>
              <Typography
                variant="h6"
                mr="auto"
                align="left"
                mb={1}
                display={"block"}
                fontSize={20}
                fontWeight={500}>
                14K White 9x7 mm Oval Engagement Ring Mounting
              </Typography>
              <ul style={{ listStyle: "none", margin: 0, paddingLeft: 0 }}>
                <li style={{ display: "inline-block" }}>
                  <Typography variant="p" fontWeight={500} fontSize={16}>
                    Item : #: 126936:765:P
                  </Typography>
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography
                    variant="p"
                    fontWeight={500}
                    fontSize={16}
                    marginLeft={4}>
                    <i className="ri-share-box-line"></i> Share
                  </Typography>
                </li>
              </ul>
            </Box>
            <Button
              variant={"contained"}
              size={"large"}
              sx={{
                boxShadow: "none",
                textTransform: "capitalize",
                backgroundColor: "#B88E1D",
                color: "#fff",
                fontWeight: 600,
                borderRadius: 0,
                px: 6,
                py: "5px",
              }}>
              LOG IN FOR PRICING
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} lg={6}>
          <ImageCase />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="p" fontSize={20} fontWeight={600}>
            There are no in-stock options for this style
          </Typography>
          <Box borderBottom={"1px solid #86715E"} pb={7} mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Location
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                Location
              </Typography>
            </Box>
            <Select
              style={{ width: "100%" }}
              className="border-0"
              placeholder="Select Color"
              options={option1}
            />
          </Box>
          <Box borderBottom={"1px solid #86715E"} pb={7} mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Jewelry State
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                Set
              </Typography>
            </Box>
            <Button
              variant={"contained"}
              size={"large"}
              sx={{
                boxShadow: "none",
                textTransform: "capitalize",
                backgroundColor: "#86715E",
                color: "#fff",
                fontWeight: 600,
                borderRadius: 4,
                fontSize: 16,
                px: 4,
                py: "3px",
                mr: 2,
              }}>
              Set
            </Button>
            <Button
              variant={"contained"}
              size={"large"}
              sx={{
                boxShadow: "none",
                textTransform: "capitalize",
                backgroundColor: "transparent",
                border: "1px solid #B88E1D",
                color: "#B88E1D",
                fontWeight: 600,
                borderRadius: 4,
                fontSize: 16,
                px: 4,
                py: "3px",
              }}>
              Unset
            </Button>
          </Box>
          <Box borderBottom={"1px solid #86715E"} pb={7} mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Quality
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                14K white
              </Typography>
            </Box>
            <Select
              style={{ width: "100%" }}
              className="border-0"
              placeholder="Select Color"
              options={option1}
            />
          </Box>
          <Box borderBottom={"1px solid #86715E"} pb={7} mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Primary Stone Type
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                Lab-Grown Diamond
              </Typography>
            </Box>
            <Button
              variant={"contained"}
              size={"large"}
              sx={{
                boxShadow: "none",
                textTransform: "capitalize",
                backgroundColor: "#86715E",
                color: "#fff",
                borderRadius: "8px",
                fontWeight: 600,
                px: 2,
                py: "5px",
              }}>
              <Typography
                variant="p"
                height={26}
                width={26}
                color={"#86715E"}
                bgcolor={"White"}
                borderRadius={10}
                fontSize={12}
                fontWeight={600}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                mr={2}>
                LG
              </Typography>
              Lab-Grown Diamonds
            </Button>
            <Button
              variant={"contained"}
              size={"large"}
              sx={{
                boxShadow: "none",
                textTransform: "capitalize",
                backgroundColor: "white",
                color: "#B88E1D",
                border: "1px solid #B88E1D",
                fontWeight: 600,
                borderRadius: "8px",
                px: 6,
                py: "5px",
                ml: 3,
              }}>
              Natural Diamonds
            </Button>
          </Box>
          <Box mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Primary Stone Shape
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                Raund
              </Typography>
            </Box>
            <Box textAlign="center" maxWidth={100}>
              <Typography
                variant="p"
                display={"inline-block"}
                width={"100%"}
                textAlign="center"
                textTransform={"capitalize"}
                marginBottom={1}>
                round
              </Typography>
              <Box
                component="img"
                className="object-contain"
                src={round}
                alt={"round"}
                width="50px"
                height="50px"
              />
            </Box>
          </Box>
          <Box mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Primary Stone Size
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                1.5 mm
              </Typography>
            </Box>
          </Box>
          <Box mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Finished State
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                Polished
              </Typography>
            </Box>
          </Box>
          <Box mb={3}>
            <Box
              mt={1}
              mb={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={600}>
                Description
              </Typography>
              <Typography
                variant="p"
                marginBottom={0}
                fontSize={16}
                fontWeight={400}>
                1/8 CTW Lab-Grown Diamond Matching Band
              </Typography>
            </Box>
          </Box>
          <Box mb={3} bgcolor={"#F3F3F3"} px={3} pb={5} pt={5} mt={5}>
            <Typography fontWeight={100} fontSize={40} variant="p">
              Customization Options
            </Typography>
            <Box>
              <Box
                mt={1}
                mb={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <Typography
                  variant="p"
                  marginBottom={0}
                  fontSize={16}
                  fontWeight={600}>
                  Exact Ring Sizing
                </Typography>
                <Typography
                  variant="p"
                  marginBottom={0}
                  fontSize={16}
                  fontWeight={400}>
                   Ship Date May Change
                </Typography>
              </Box>
              <Select
                style={{ width: "100%" }}
                className="border-0"
                placeholder="Select Color"
                options={option1}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
