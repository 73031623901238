import { Box, Grid, Icon, ListItem, Typography } from "@mui/material";
import React from "react";
import BreadcrumbsPage from "../../components/common/breadcrumbs";
import bgImage from "../../assets/images/blackbg.png";

import ContactForm from "./ContactForm";
import StoreLocation from "./StoreLocation";

const ContactUs = () => {
  const data = [
    {
      url: "/",
      icon: "ri-phone-line",
    },
    {
      url: "/",
      icon: "ri-mail-line",
    },
    {
      url: "/",
      icon: "ri-facebook-line",
    },
    {
      url: "/",
      icon: "ri-instagram-line",
    },
  ];
  return (
    <>
      <Grid container gap={4}>
        <Grid container px={2} maxWidth="1580px" mx="auto">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/trade" },
            ]}
          />
        </Grid>
      </Grid>
      <Typography
        mt={3}
        mb={8}
        fontSize={40}
        fontWeight={300}
        textAlign={"center"}
        width={"100%"}>
        Contact Us
      </Typography>

      <Box
        sx={{
          mb: 7,
          position: "relative",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          width: "100%",
          height: { xs: 500, md: 600 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box>
          <Typography
            mt={3}
            mb={3}
            fontSize={50}
            fontWeight={700}
            color={"white"}
            textAlign={"center"}
            width={"100%"}>
            Contact Us
          </Typography>
          <Box
            component="ul"
            sx={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}>
            {data.map((item, key) => (
              <ListItem
                key={key}
                sx={{
                  display: "inline-block",
                  backgroundColor: "#B88E1D",
                  width: "auto",
                  color: "white",
                  margin: "0 20px",
                  fontSize: { xs: 25, md: 35 },
                  padding: { xs: "5px 15px", md: "5px 20px" },
                  borderRadius: "4px",
                  lineHeight: "normal",
                }}>
                <Icon className={item.icon} />
              </ListItem>
            ))}
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <StoreLocation />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography fontSize={40} fontWeight={200}>
            We can contact you
          </Typography>
          <ContactForm />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;
