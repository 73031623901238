import { Box, Button, Typography } from "@mui/material";
import React from "react";

import img from "../../../assets/images/icons/locate.svg";
import { Link } from "react-router-dom";

const LocateJeweler = ({ onTabChange, activeTab }) => {
  return (
    <>
      <Box display={"flex"}>
        <Link
          to="/location"
          style={{ textDecoration: "none", margin: "0 auto" }}>
          <Box
            bgcolor={"#B88E1D"}
            borderRadius={"8px"}
            py={2}
            px={6}
            mx={"auto"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Box
              component="img"
              src={img}
              alt="locate"
              width={40}
              height={75}
              style={{ objectFit: "contain" }}></Box>
            <Box borderLeft={"1px solid #FFFFFF"} ml={3} pl={3}>
              <Typography
                fontSize={16}
                fontWeight={500}
                variant="p"
                display={"block"}
                color={"white"}>
                To Purchase This Item
              </Typography>
              <Typography
                fontSize={20}
                fontWeight={600}
                variant="p"
                color={"white"}>
                locate a Jeweler
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
      <Button
        variant={"contained"}
        size={"large"}
        onClick={() => {
          onTabChange(activeTab - 1);
        }}
        sx={{
          boxShadow: "none",
          textTransform: "capitalize",
          color: "white",
          borderRadius: 0,
          fontWeight: 600,
          backgroundColor: "#B88E1D",
          border: "1px solid #B88E1D",
          pl: 2,
          pr: 5,
          py: "2px",
          my: 10,
        }}>
        <i
          style={{ fontSize: "20px", lineHeight: "normal", marginRight: 10 }}
          className="ri-arrow-left-s-line"></i>{" "}
        Back
      </Button>
    </>
  );
};

export default LocateJeweler;
