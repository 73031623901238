import React from "react";
import { Box, Button, Grid, Input, Typography } from "@mui/material";
import Select from "react-select";

const Engravings = ({ onTabChange, activeTab }) => {
  const option1 = [
    { value: "1", label: "Helvetica" },
    { value: "2", label: "2" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
  ];
  const data = [
    {
      color: "#B88E1D;",
      title: "none",
    },
    {
      color: "#000000;",
      title: "black",
    },
    {
      color: "#0056A3;",
      title: "blue",
    },
    {
      color: "#663B1A;",
      title: "brown",
    },
    {
      color: "#008846;",
      title: "green",
    },
    {
      color: "#FA9CC1;",
      title: "pink",
    },
    {
      color: "#A60C18;",
      title: "red",
    },
  ];
  return (
    <>
      <Typography fontSize={20} fontWeight={600} variant="h5" mb={7}>
        Engravings & Patterns (optional)
      </Typography>
      <Typography fontSize={16} fontWeight={400} variant="p">
        Precision Laser Engraving
      </Typography>
      <Typography
        fontSize={{ xs: 16, md: 40 }}
        fontWeight={600}
        variant="h2"
        mb={4}>
        Inside Engraving
      </Typography>
      <Typography fontSize={16} fontWeight={400} variant="p" display={"block"}>
        Engraving Type
      </Typography>
      <Typography fontSize={16} fontWeight={600} variant="p">
        Laser Engraving
      </Typography>
      <Grid container spacing={2} mt={3} mb={5}>
        <Grid item xs={6} sm={4} md={6} lg={4} xl={6}>
          <Typography
            fontWeight={400}
            fontSize={16}
            variant="p"
            mb={1}
            display={"block"}>
            Message
          </Typography>
          <Input
            style={{
              border: "1px solid #86715E",
              width: "100%",
              padding: "0 10px",
            }}
            placeholder="Search"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={4} xl={6}>
          <Typography
            fontWeight={400}
            fontSize={16}
            variant="p"
            mb={1}
            display={"block"}>
            Font
          </Typography>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Font"
            options={option1}
          />
        </Grid>
      </Grid>

      <Typography fontSize={16} fontWeight={400} variant="p" mb={2}>
        Color
      </Typography>
      <Box bgcolor={"#F3F3F3"} py={4} px={6}>
        <Grid container spacing={2}>
          {data.map((item, key) => {
            return (
              <Grid key={key} item xs={6} sm={4} md={6} lg={4} xl={3}>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  borderRadius={"8px"}
                  textTransform={"capitalize"}
                  textAlign={"center"}
                  color={"white"}
                  py={"4px"}
                  bgcolor={item.color}>
                  {item.title}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box
        mt={4}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => {
            onTabChange(activeTab - 1);
          }}
          sx={{
            boxShadow: "none",
            textTransform: "capitalize",
            backgroundColor: "#B88E1D !important",
            color: "white",
            borderRadius: 0,
            fontWeight: 600,
            border: "1px solid #B88E1D",
            pl: 2,
            pr: 3,
            py: "5px",
          }}>
          <i
            style={{ fontSize: "20px", lineHeight: "normal", marginRight: 10 }}
            className="ri-arrow-left-s-line"></i>{" "}
          Back
        </Button>
        <Box>
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
              backgroundColor: "white",
              color: "#B88E1D",
              fontWeight: 600,
              borderRadius: 0,
              px: 3,
              py: "5px",
              border: " 1px solid #B88E1D",
              mr: 2,
            }}>
            Locate a Jeweler
            <i
              sx={{
                fontSize: "20px",
                lineHeight: "normal",
                marginLeft: { md: 0, lg: 30 },
              }}
              className="ri-arrow-right-circle-fill"></i>{" "}
          </Button>
          <Button
            variant={"contained"}
            size={"large"}
            onClick={() => {
              onTabChange(activeTab + 1);
            }}
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
              backgroundColor: "#86715E",
              color: "#fff",
              fontWeight: 600,
              borderRadius: 0,
              px: 6,
              py: "5px",
            }}>
            Matching Band
            <i
              style={{ fontSize: "20px", lineHeight: "normal", marginLeft: 10 }}
              className="ri-arrow-right-s-line"></i>{" "}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Engravings;
