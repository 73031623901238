// src/components/trade/productDetail/index.js
import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Specifications from "./specifications";

import ProductDetailTitle from "./productDetailTitle";

import ProductImages from "./productImages";

import SetStones from "./SetStones";
import MountingOptions from "./MountingOptions";
import LocateJeweler from "./LocateJeweler";
import Engravings from "./Engravings";
import Matching from "./Matching";

export default function ProductDetail({ activeTab, onTabChange }) {
  return (
    <Box width="100%" padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={3}>
          <ProductDetailTitle />
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              variant="p"
              fontWeight={500}
              fontSize={16}
              marginRight={1}>
              Item #: 126936:765:P
            </Typography>
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                margin: 0,
                padding: 0,
              }}>
              <li>
                <Box
                  height={28}
                  width={28}
                  marginRight={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={10}>
                  <i
                    style={{ color: "#1877F2", fontSize: 30 }}
                    className="ri-facebook-circle-fill"></i>
                </Box>
              </li>
              <li>
                <Box
                  height={28}
                  width={28}
                  marginRight={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={10}>
                  <i
                    style={{
                      color: "color(srgb 0.9136 0.1173 0.1401)",
                      fontSize: 30,
                    }}
                    className="ri-pinterest-fill"></i>
                </Box>
              </li>
              <li>
                <Box
                  height={28}
                  width={28}
                  marginRight={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={10}
                  bgcolor={"black"}>
                  <i
                    style={{ color: "white", fontSize: 20 }}
                    className="ri-twitter-x-fill"></i>
                </Box>
              </li>
              <li>
                <Box
                  height={28}
                  width={28}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bgcolor={"#1877F2"}
                  borderRadius={10}>
                  <i
                    style={{ color: "white", fontSize: 17 }}
                    className="ri-mail-line"></i>
                </Box>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProductImages />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          {activeTab === 0 ? (
            <MountingOptions onTabChange={onTabChange} activeTab={activeTab} />
          ) : activeTab === 1 ? (
            <SetStones onTabChange={onTabChange} activeTab={activeTab} />
          ) : activeTab === 2 ? (
            <Engravings onTabChange={onTabChange} activeTab={activeTab} />
          ) : activeTab === 3 ? (
            <Matching onTabChange={onTabChange} activeTab={activeTab} />
          ) : activeTab === 4 ? (
            <LocateJeweler onTabChange={onTabChange} activeTab={activeTab} />
          ) : (
            <></>
          )}

          {/* <Paper elevation={3} style={{ padding: "16px" }}>
            <Typography variant="h5">
              14K White 9x7 mm Oval Engagement Ring Mounting
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Item #: 126936:765:P
            </Typography>
            <Typography variant="h6">Specifications</Typography>
            <Typography>Weight: 2.3754 DWT (3.69 grams)</Typography>
            <Typography>Solidarity: Solid</Typography>
            <Typography>Ring Back Design: Closed Back</Typography>
            <Typography>Plating Type: Rhodium-Plated</Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "16px" }}>
              Modify This Style
            </Button>
          </Paper> */}
          <Specifications />
        </Grid>
      </Grid>
    </Box>
  );
}
