import React from "react";

// UI Component
import { Box, Button, Stack, Typography } from "@mui/material";

// Images
import ringImg from "../../../assets/images/ring.png";

// Slider Libs
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules"; // Import Navigation from swiper/modules

import "swiper/css";
import "swiper/css/navigation";

// Rings Images
import img1 from "../../../assets/images/rings/ring-4-1.svg";
import img2 from "../../../assets/images/rings/ring-4-2.svg";
import img3 from "../../../assets/images/rings/ring-4.svg";
import img4 from "../../../assets/images/rings/jewelry_PNG6774 1.svg";
import { Link } from "react-router-dom";

const ProductImages = () => {
  const data = [img1, img2, img3, img1, img2, img3, img1, img2, img3];

  return (
    <Stack bgcolor="#F3F3F3" p={4} width="100%" height="100%">
      <Box
        component="img"
        src={ringImg}
        alt="Main product image of a ring"
        sx={{
          width: { xs: "100%", sm: "50%", md: "100%" },
          margin: " 0 auto 40px",
        }}
      />
      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        loop={true}
        modules={[Navigation]}
        navigation={true}
        style={{ width: "100%", marginBottom: 40 }}>
        {data.map((slide, index) => (
          <SwiperSlide key={index} style={{ textAlign: "center" }}>
            <Box
              component="img"
              className="object-contain"
              src={slide}
              // bgcolor={"black"}
              alt={`slider image ${index}`}
              width={"60%"}
              height={"100%"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        variant="outlined"
        size="large"
        bgcolor="transparent"
        width={"100%"}
        borderRadius={8}
        style={{
          fontWeight: "600",
          color: "#86715E",
          border: "1px solid #86715E",
          marginBottom: "24px",
        }}
        py={2.5}>
        VIEW FULL DETAILS
      </Button>
      <Box
        borderRadius={1}
        marginBottom={3}
        style={{ border: "1px solid #86715E" }}>
        <Box
          textAlign={"center"}
          color={"white"}
          fontWeight={600}
          py={1}
          style={{ borderRadius: "6px" }}
          bgcolor={"#86715E"}>
          Modify This Style
        </Box>
        <Typography px={3} py={2}>
          Take customization further using Stuller's CAD/CAM Services. Make easy
          modifications to the center stone, accent stones, and design.{" "}
          <Link style={{ color: "#3295FF", textDecoration: "none" }} href="#">
            Learn More
          </Link>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"7px 7px 7px 30px"}
        borderRadius={1}
        style={{ border: "1px solid #86715E" }}>
        <Box>
          <Typography variant="h6" fontSize={16} fontWeight={600}>
            Looking for the Matching Band?
          </Typography>
          <Typography variant="p">Series: 126937</Typography>
        </Box>
        <Box
          component="img"
          src={img4}
          alt="Main product image of a ring"
          width={80}
          height={80}
          bgcolor={"white"}
          border={"1px solid #86715E"}
          borderRadius={1}
          padding={1}
        />
      </Box>
    </Stack>
  );
};

export default ProductImages;
