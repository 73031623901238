import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Select from "react-select";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import ValidationMsg from "../../components/common/ValidationMsg";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      businessPhone: "",
      accountNumber: "",
      preferredContact: "",
      topic: "",
      message: "",
      captcha: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      email: Yup.string().email().required("Please enter email"),
      businessPhone: Yup.number()
        .required("Please enter business phone")
        .min(0),
      accountNumber: Yup.number()
        .required("Please enter account number")
        .min(0),
      preferredContact: Yup.string().required("Please enter preferred contact"),
      topic: Yup.string().required("Please enter topic"),
      message: Yup.string().required("Please enter message"),
      captcha: Yup.boolean().required("Please clear captcha"),
    }),
    onSubmit: (value) => {
      console.log(value);
    },
  });

  const option3 = [
    { value: "1", label: "Product Questions and General Questions" },
    { value: "2", label: "Strawberry" },
    { value: "3", label: "Vanilla" },
  ];

  return (
    <Box bgcolor={"#F3F3F3"} p={5} mt={4}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#">
        <Grid spacing={2} container>
          <Grid item xs={12} md={4} lg={6}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              First Name
            </Typography>
            <input
              style={{
                border: "none",
                borderRadius: 0,
                width: "100%",
                padding: "10px 10px",
                fontSize: 16,
              }}
              type="text"
              name="firstName"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstName}
            />
            {validation.touched.firstName && validation.errors.firstName && (
              <ValidationMsg text={validation.errors.firstName} />
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              Last Name
            </Typography>
            <input
              style={{
                border: "none",
                borderRadius: 0,
                width: "100%",
                padding: "10px 10px",
                fontSize: 16,
              }}
              type="text"
              name="lastName"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastName}
            />
            {validation.touched.lastName && validation.errors.lastName && (
              <ValidationMsg text={validation.errors.lastName} />
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={12}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              email
            </Typography>
            <input
              style={{
                border: "none",
                borderRadius: 0,
                width: "100%",
                padding: "10px 10px",
                fontSize: 16,
              }}
              type="email"
              name="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email}
            />
            {validation.touched.email && validation.errors.email && (
              <ValidationMsg text={validation.errors.email} />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={12}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              business Phone
            </Typography>
            <input
              style={{
                border: "none",
                borderRadius: 0,
                width: "100%",
                padding: "10px 10px",
                fontSize: 16,
              }}
              type="number"
              name="businessPhone"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.businessPhone}
            />
            {validation.touched.businessPhone &&
              validation.errors.businessPhone && (
                <ValidationMsg text={validation.errors.businessPhone} />
              )}
          </Grid>
          <Grid item xs={12} md={6} lg={12}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              account Number
            </Typography>
            <input
              style={{
                border: "none",
                borderRadius: 0,
                width: "100%",
                padding: "10px 10px",
                fontSize: 16,
              }}
              type="number"
              name="accountNumber"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.accountNumber}
            />
            {validation.touched.accountNumber &&
              validation.errors.accountNumber && (
                <ValidationMsg text={validation.errors.accountNumber} />
              )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              preferred Contact
            </Typography>
            <RadioGroup
              row
              name="preferredContact"
              value={validation.values.preferredContact}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}>
              <FormControlLabel
                value="savings"
                control={<Radio />}
                label="Email"
              />
              <FormControlLabel
                value="current"
                control={<Radio />}
                label="Business Phone"
              />
            </RadioGroup>
            {validation.touched.preferredContact &&
              validation.errors.preferredContact && (
                <ValidationMsg text={validation.errors.preferredContact} />
              )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              topic
            </Typography>
            <Select
              name="topic"
              value={option3.find(
                (item) => item.value === validation.values.topic
              )}
              onChange={({ value }) => validation.setFieldValue("topic", value)}
              placeholder="Select topic"
              options={option3}
              className="border-0"
            />
            {validation.touched.topic && validation.errors.topic && (
              <ValidationMsg text={validation.errors.topic} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              textTransform={"capitalize"}>
              What can we help you with?
            </Typography>
            <textarea
              name="message"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.message}
              rows={5}
              style={{
                border: "none",
                borderRadius: 0,
                width: "100%",
                padding: "10px 10px",
                fontSize: 16,
              }}
            />
            {validation.touched.message && validation.errors.message && (
              <ValidationMsg text={validation.errors.message} />
            )}
          </Grid>
          <Grid item xs={12}>
            <ReCAPTCHA
              sitekey="YOUR_GOOGLE_RECAPTCHA_SITE_KEY"
              onChange={({ value }) =>
                validation.setFieldValue("captcha", value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              sx={{
                px: 10,
                backgroundColor: "#86715E",
                border: "1px solid #86715E",
                textTransform: "capitalize",
                color: "white",
                fontSize: 16,
                fontWeight: 600,
                borderRadius: 0,
                "&:hover": {
                  color: "#86715E",
                },
              }}>
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ContactForm;
