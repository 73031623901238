import { connect } from "react-redux";
import { cartPage } from "./cart.component";

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export const CartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(cartPage);
