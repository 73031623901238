import React from "react";
import bgImage from "../../assets/images/locationbg.png";
import icon from "../../assets/images/icons/locate.svg";
import { Box, Button, Grid, Typography } from "@mui/material";

const Location = () => {
  return (
    <>
      <Box
        sx={{
          mb: 7,
          position: "relative",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          width: "100%",
          height: { xs: 500, md: 600, lg: 700 },
        }}>
        <Box
          bgcolor={"#B88E1DC7"}
          position={"absolute"}
          width={"100%"}
          bottom={0}
          py={3}
          px={{ sm: 5, lg: 10 }}>
          <Grid container alignItems={"center"}>
            <Grid
              item
              lg={6}
              display={"flex"}
              pl={{ xs: 0, lg: 20 }}
              marginBottom={{ xs: 1, lg: 0 }}
              position={"relative"}>
              <Box
                component={"img"}
                src={icon}
                width={{ lg: 130 }}
                height={{ lg: 280 }}
                marginRight={{ xs: 1, lg: 0 }}
                sx={{
                  position: { lg: "absolute" },
                  top: { lg: "-200px" },
                  left: 0,
                }}
              />
              <Box>
                <Typography fontSize={25} fontWeight={500} color={"white"}>
                  To Purchase This Item
                </Typography>
                <Typography fontSize={40} fontWeight={600} color={"white"}>
                  locate a Jeweler
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Typography fontSize={16} fontWeight={500} color={"white"}>
                Use Locate a Jeweler to find jewelry stores in your area (and
                nationwide) that carry Stuller products. Dedicated retailers
                will have an assortment of our jewelry, and they can order any
                items you choose from this website.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Typography
        fontSize={16}
        fontWeight={400}
        mb={4}
        display={"block"}
        width={"100%"}>
        Enter a location to find the closest Stuller retailers near you!
      </Typography>
      <Grid container>
        <Grid item lg={8}>
          <Box border="1px solid #B88E1D" width={"100%"} mr={10}>
            <Button
              sx={{
                backgroundColor: "#B88E1D",
                border: "1px solid #B88E1D",
                borderRadius: 0,
                color: "white",
                px: 5,
                py: 1,
              }}>
              <i
                style={{ fontSize: 22, lineHeight: "normal" }}
                className="ri-search-line"></i>
            </Button>
          </Box>
        </Grid>
        <Grid item lg={4} textAlign={"end"}>
          <Button
            sx={{
              backgroundColor: "#B88E1D",
              border: "1px solid #B88E1D",
              borderRadius: 0,
              color: "white",
              px: 9,
              height: "100%",
            }}>
            LOCATE A JEWELER
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Location;
