import React from 'react';
import { Box } from '@mui/material';
import SearchBar from '../search';
import logo from '../../../assets/images/swag-logo.png';

const Header = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'space-between', padding: '0 15px 40px', backgroundColor: '#F9F9F9' }}>
            <img src={logo} alt="SWAG Logo" style={{ maxWidth: '150px' }}/>
            <SearchBar />
        </Box>
    );
};

export default Header;