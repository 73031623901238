import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginContainer } from "./views/login/login.container";
import { DashboardContainer } from "./views/dashboard/dashboard.container";
import { ProductsContainer } from "./views/products/products.container";
import DefaultLayout from "./components/layouts/defaultLayout";
import { TradeContainer } from "./views/trade/trade.container";
import { SetPageContainer } from "./views/setpage/setpage.container";
import { CartContainer } from "./views/cart/cart.container";
import { PlaceorderContainer } from "./views/placeorder/placeorder.container";
import { ProfileContainer } from "./views/profile/profile.container";
import OrderConformation from "./views/placeorder/OrderConformation";
import Location from "./views/location/Index";
import ContactUs from "./views/contactus/Index";
import Return from "./views/return/Index";

export const RoutePath = () => (
  <React.Fragment>
    {window.location.pathname !== "/login" ? (
      <DefaultLayout>
        <Routes>
          <Route path="/" element={<DashboardContainer />} />
          <Route path="/dashboard" element={<DashboardContainer />} />
          <Route path="/products" element={<ProductsContainer />} />
          <Route path="/return" element={<Return />} />
          <Route path="/trade" element={<TradeContainer />} />
          <Route path="/setpage" element={<SetPageContainer />} />
          <Route path="/cart" element={<CartContainer />} />
          <Route path="/order/place" element={<PlaceorderContainer />} />
          <Route path="/order/conformation" element={<OrderConformation />} />
          <Route path="/profile" element={<ProfileContainer />} />
          <Route path="/location" element={<Location />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </DefaultLayout>
    ) : (
      <Routes>
        <Route path="/login" element={<LoginContainer />} />
      </Routes>
    )}
  </React.Fragment>
);
