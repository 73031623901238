import React from 'react';
import { Grid } from '@mui/material';
import ButtonPrimary from '../../common/buttonPrimary';
import SectionTitle from '../../common/sectionTitle';

const categories = [
    "FOR HER",
    "FOR HIM",
    "GIFTS UNDER $250",
    "GIFT GUIDE"
];

export default function CategoriesVariant(){
    return (
        <React.Fragment>
            <SectionTitle width="100%" py={0} pb={2} title='Categories Inspired By You' />
       
            <Grid container spacing={2} pb={2} justifyContent="center">
                {categories.map((category, index) => (
                    <Grid item key={index} flexGrow={1}>
                        <ButtonPrimary sx={{width:"100%"}}>
                            {category}
                        </ButtonPrimary>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}