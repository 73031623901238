import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BreadcrumbsPage from "../../components/common/breadcrumbs/index.jsx";
import React from "react";

import img1 from "../../assets/images/7532c5c215b7a8d0b3f347d5a44243a6.svg";

export function cartPage(props) {
  return (
    <>
      <Grid container gap={2}>
        <Grid item px={2} maxWidth="1580px">
          <BreadcrumbsPage
            breadcrumbs={[
              { label: "HOME", href: "/" },
              { label: "PRODUCTS", href: "/products" },
              { label: "ACCENTED ENGAGEMENT RING", href: "/cart" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={{ xs: 25, md: 40 }}
            fontWeight={100}
            textAlign={{ md: "center" }}
            width={"100%"}
            display={"inline-block"}
            my={5}
            variant="p">
            14K White 1/8 CTW Lab-Grown Diamond Matching Band
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}>
            <Box>
              <Typography
                variant="h6"
                mr="auto"
                align="left"
                mb={1}
                display={"block"}
                fontSize={20}
                fontWeight={500}>
                14K White 9x7 mm Oval Engagement Ring Mounting
              </Typography>
              <ul style={{ listStyle: "none", margin: 0, paddingLeft: 0 }}>
                <li style={{ display: "inline-block" }}>
                  <Typography variant="p" fontWeight={500} fontSize={16}>
                    Item : #: 126936:765:P
                  </Typography>
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography
                    variant="p"
                    fontWeight={500}
                    fontSize={16}
                    marginLeft={4}>
                    <i className="ri-share-box-line"></i> Share
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} lg={6}>
          <Box bgcolor={"#F3F3F3"} p={2} height={"100%"}>
            <Grid container spacing={2}>
              {Array(10)
                .fill(1)
                .map((item, key) => {
                  return (
                    <Grid item xs={6} md={3} lg={4} xl={6}>
                      <Box
                        key={key}
                        component="img"
                        src={img1}
                        style={{ objectFit: "cover" }}
                        alt={"round"}
                        width="100%"
                        height="100%"
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box pl={{ md: 4 }}>
            <Typography variant="h6" fontSize={40} fontWeight={600}>
              ₹2 25 568
            </Typography>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={400}>
              (MRP Inclusive of all taxes)
            </Typography>
          </Box>
          <Typography
            display={"block"}
            variant="p"
            fontSize={16}
            fontWeight={600}
            mt={4}>
            Dual-Tone Halo Diamond Finger Ring
          </Typography>
          <Box
            display={"flex"}
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
            mt={4}
            borderRadius={"8px"}
            overflow={"hidden"}
            style={{ border: " 1px solid #86715E" }}>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "0",
                overflow: "hidden",
                boxShadow: "none",
                mt: 1,
                mb: 1,
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                        border: "none",
                      }}>
                      Size
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                        border: "none",
                      }}>
                      Metal
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                        border: "none",
                      }}>
                      Diamond
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: "white",
                      borderColor: "transparent",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      12 (51.8 mm)
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      14 KT_Yellow
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      IJ-SI
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              sx={{
                backgroundColor: "#B88E1D",
                fontSize: 16,
                fontWeight: 600,
                color: "white",
                height: "100%",
                padding: { sm: "37px 30px" },
                width: { xs: "100%", sm: "auto" },
                borderRadius: "5px",
              }}>
              CUSTOMISE
            </Button>
          </Box>
          <Box mt={4} display={"flex"}>
            <Button
              style={{
                backgroundColor: "#B88E1D",
                fontSize: 16,
                fontWeight: 600,
                color: "white",
                borderRadius: "5px",
                width: "100%",
              }}>
              <i
                style={{ marginRight: 14, fontSize: 19, lineHeight: "normal" }}
                className="ri-shopping-cart-fill"></i>{" "}
              ADD TO CART
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(249, 249, 249)",
                fontSize: 16,
                fontWeight: 600,
                color: "#86715E",
                borderRadius: "4px",
                border: "2px solid #86715E",
                maxWidth: 38,
                width: 38,
                height: 38,
                margin: "0 20px",
              }}>
              <i
                style={{ fontSize: 25, lineHeight: "normal" }}
                className="ri-heart-add-line"></i>{" "}
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(249, 249, 249)",
                color: "#86715E",
                borderRadius: "4px",
                border: "2px solid #86715E",
                maxWidth: 38,
                width: 38,
                height: 38,
              }}>
              <i
                style={{ fontSize: 25, lineHeight: "normal" }}
                className="ri-share-line"></i>{" "}
            </Button>
          </Box>
          <Typography
            display={"block"}
            variant="p"
            fontSize={16}
            fontWeight={600}
            mt={8}
            mb={4}>
            Dual-Tone Halo Diamond Finger Ring
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            border={"1px solid #86715E"}
            borderRadius={"8px"}
            px={5}
            py={"7px"}>
            <Typography fontWeight={500} fontSize={16}>
              <i
                style={{
                  color: "#86715E",
                  fontSize: 18,
                  lineHeight: "normal",
                  marginRight: 6,
                }}
                className="ri-focus-3-line"></i>{" "}
              Enter Pincode
            </Typography>
            <Typography fontWeight={500} fontSize={16}>
              Locate Me
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            border={"1px solid #86715E"}
            borderRadius={"8px"}
            mt={2}
            px={5}
            py={"7px"}>
            <Typography fontWeight={500} fontSize={16}>
              <i
                style={{
                  color: "#86715E",
                  fontSize: 18,
                  lineHeight: "normal",
                  marginRight: 6,
                }}
                className="ri-calendar-schedule-line"></i>{" "}
              Expected Delivery Date
            </Typography>
          </Box>
          <Box
            mt={8}
            display={"flex"}
            alignItems={"center"}
            justifyContent={{ xs: "center", sm: "space-between" }}
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
            borderBottom={"1px solid #86715E"}
            pb={5}>
            <Box textAlign={"center"} mb={{ xs: 4, sm: 0 }}>
              <Typography fontWeight={600} fontSize={16}>
                <i
                  style={{
                    color: "#B88E1D",
                    fontSize: 18,
                    lineHeight: "normal",
                    marginRight: 2,
                  }}
                  className="ri-home-line"></i>{" "}
                Try At Home
              </Typography>
              <Typography fontWeight={500} mt={2} mb={3} fontSize={16}>
                Trial upto 5 designs
              </Typography>
              <Button
                style={{
                  backgroundColor: "#B88E1D",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "white",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  padding: "2px 45px",
                  minWidth: "245px",
                }}>
                Book Appointment
              </Button>
            </Box>
            <Box textAlign={"center"}>
              <Typography fontWeight={600} fontSize={16}>
                <i
                  style={{
                    color: "#86715E",
                    fontSize: 18,
                    lineHeight: "normal",
                    marginRight: 2,
                  }}
                  className="ri-store-2-line"></i>
                Store Availability
              </Typography>
              <Typography fontWeight={500} mt={2} mb={3} fontSize={16}>
                Find designs in store
              </Typography>
              <Button
                style={{
                  backgroundColor: "#86715E",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "white",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  padding: "2px 45px",
                  minWidth: "245px",
                }}>
                Find In Store
              </Button>
            </Box>
          </Box>
          <Box mt={5} borderBottom={"1px solid #86715E"} pb={3}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={4}>
              Product Details
            </Typography>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              Gold
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "0",
                overflow: "hidden",
                boxShadow: "none",

                mb: 1,
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Dimensions
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Weight
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Purity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Width: 12.75 mm
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Gross: 1.721 g
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      14 KT
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Height: 2.2 mm
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}></TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box mt={5} borderBottom={"1px solid #86715E"} pb={3}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              Diamond
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "0",
                overflow: "hidden",
                boxShadow: "none",
                mb: 1,
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Type
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Setting
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Total Weight
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      IJ-SI
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Settiing: Prong
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      0.055 ct
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                    }}>
                    <TableCell
                      style={{
                        border: "none",
                      }}></TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Total No: 7
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt={5} borderBottom={"1px solid #86715E"} pb={3}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              Gold
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "0",
                overflow: "hidden",
                boxShadow: "none",

                mb: 1,
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Dimensions
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Weight
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingTop: 9,
                        paddingBottom: 9,
                        backgroundColor: "white",
                        border: "none",
                      }}>
                      Purity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Width: 12.75 mm
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Gross: 1.721 g
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      14 KT
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: "color(srgb 0.9764 0.9765 0.9766)",
                    }}>
                    <TableCell
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: "none",
                        fontSize: 16,
                      }}>
                      Height: 2.2 mm
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}></TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt={5} borderBottom={"1px solid #86715E"} pb={2}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              DESCRIPTION
            </Typography>
            <Typography
              display={"block"}
              bgcolor={"white"}
              p={2}
              variant="p"
              fontSize={12}
              fontWeight={400}
              mt={0}
              mb={2}>
              A breathtaking 18 Karat white and rose gold Finger Ring with a
              stunning halo of Diamonds, combining two tones to create a
              dazzling masterpiece. Capture attention with this stunning Halo
              Diamond Finger Ring, a true masterpiece of brilliance. The gold
              design elevates its elegance, while the central Diamond is framed
              by a halo of shimmering stones. Perfect for special moments that
              deserve timeless style.See more
            </Typography>
          </Box>
          <Box mt={5}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              Manufactured by
            </Typography>
            <Typography
              display={"block"}
              bgcolor={"white"}
              p={2}
              variant="p"
              fontSize={12}
              fontWeight={400}
              mt={0}
              mb={2}>
              CaratLane Trading Pvt Ltd, <br /> 4th & 5th Floor, 6/1, Garden
              Road, bakkam, Thane, Mumbai - ***006
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              Quantity
            </Typography>
            <Typography
              display={"block"}
              bgcolor={"white"}
              px={2}
              py={"3px"}
              variant="p"
              fontSize={12}
              fontWeight={400}
              mt={0}
              mb={2}>
              IN
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography
              display={"block"}
              variant="p"
              fontSize={16}
              fontWeight={600}
              mt={0}
              mb={2}>
              Country of Origin
            </Typography>
            <Typography
              display={"block"}
              bgcolor={"white"}
              px={2}
              py={"3px"}
              variant="p"
              fontSize={12}
              fontWeight={400}
              mt={0}
              mb={2}>
              India
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
